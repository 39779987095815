<template>
  <div>
    <div v-if="features && Object.keys(features).length">
      <detail-component
        :features="features"
        :organisation="organisation"
        :user="user"
        :infoLayer="infoLayer"
      ></detail-component>
    </div>
    <div v-else><h4>Selecteer een element</h4></div>
  </div>
</template>
<style>
detail-component {
  height: 100%;
}
</style>

<script>
import Feature from "ol/Feature";
import { fromCircle } from "ol/geom/Polygon";
import { Circle as geomCircle } from "ol/geom";
import { intersects, and } from "ol/format/filter";
import WFS from "ol/format/WFS";
import { createEmpty, extend } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import Geometry from "ol/geom/Geometry";
import * as OlLayers from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import Vector from "ol/source/Vector";
import Draw from "ol/interaction/Draw";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import axios from "axios";

import * as moment from "moment";

import DetailComponent from "./DetailComponent.vue";

export default {
  name: "InfoComponent",
  components: {
    DetailComponent,
  },
  props: {
    organisation: Object,
    user: Object,
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.clickEvent,
      (newValue, oldValue) => {
        this.searchElements(newValue);
      }
    );
  },
  mounted() {
    this.searchElements(this.$store.getters.clickEvent);
  },
  unmounted() {
    this.$store.getters.getMap.removeLayer(this.infoLayer);
    this.unwatch();
  },
  data() {
    return {
      layers: {
        highlightLayer: null,
      },
      protocol: [],
      features: undefined,
      types: {},
      infoLayer: {},
    };
  },
  methods: {
    searchElements(coordinates) {
      console.log(coordinates);
      this.clear();
      const body = {};
      body.geojson = coordinates;

      this.sendRequest(body).then((response) => {
        this.readGeoJsonFeatures(response.data);
      });
    },

    sendRequest(data) {
      let headers = {};
      return axios({
        method: "post",
        url: elementsFindUrl,
        data,
      });
    },

    /**
     * Highlights features on the map, takes in account layer nuances.
     * E.g. no highlight for Bestemmingsplannen
     * @param features [description]
     */
    highlightFeatures(features) {
      // By default, show the features on the highlightLayer, @TODO: if we want to draw with them, add them to the drawinglayer
      this.infoLayer = this.highlightLayer();

      // Clear the old features and add the new features to the highlightLayer
      // so they can be highlighted or the drawingLayer so they can be edited
      this.infoLayer.getSource().clear();
      this.infoLayer.getSource().addFeatures(features);
    },

    readGeoJsonFeatures(features) {
      this.highlightFeatures(new GeoJSON().readFeatures(features));
      this.features = new GeoJSON().readFeatures(features);
    },

    /**
     * Clear all features
     */
    clear() {
      // Clear layer
      this.highlightLayer().getSource().clear();

      // Remove features
      this.features = undefined;
    },

    /**
     * Create a layer that is used for showing features, this layer will not be editable
     */
    highlightLayer(layerText = "") {
      if (!this.layers.highlightLayer) {
        var source = new Vector();

        this.layers.highlightLayer = new VectorLayer({
          title: "Selectielaag",
          source: source,
        });

        this.layers.highlightLayer.set("styleColors", this.styleColors);
        this.layers.highlightLayer.setStyle(function (feature) {
          let styleColors = {
            transparent: {
              fill: "rgba(0, 0, 0, 0)",
              stroke: "rgba(0, 0, 0, 0)",
            },
            blackstroke: {
              fill: "rgba(0, 0, 0, 0)",
              stroke: "rgba(0, 0, 0, 1)",
            },
            greenstroke: {
              fill: "rgba(0, 0, 0, 0)",
              stroke: "#57cc43",
            },
            grey: {
              fill: "rgba(100, 100, 100, 0.7)",
              stroke: "#646464",
            },
            lightgrey: {
              fill: "rgba(150, 150, 150, 0)",
              stroke: "#646464",
            },
            red: {
              fill: "rgba(244, 76, 65, 0.5)",
              stroke: "#f44c41",
            },
            blue: {
              fill: "rgba(0, 51, 255, 0.5)",
              stroke: "#0033ff",
            },
            bluestroke: {
              fill: "rgba(0, 0, 0, 0)",
              stroke: "#0033ff",
            },
            darkblue: {
              fill: "rgba(23, 20, 127, 0.5)",
              stroke: "#17147f",
            },
            cyan: {
              fill: "rgba(66, 194, 244, 0.5)",
              stroke: "#42c2f4",
            },
            green: {
              fill: "rgba(87, 204, 67, 0.5)",
              stroke: "#57cc43",
            },
            purple: {
              fill: "rgba(175, 65, 244, 0.5)",
              stroke: "#af41f4",
            },
            yellow: {
              fill: "rgba(241, 244, 65, 0.5)",
              stroke: "#f1f441",
            },
            orange: {
              fill: "rgba(244, 148, 65, 0.5)",
              stroke: "#f49441",
            },
            darkgreen: {
              fill: "rgba(1, 128, 1, 0.5)",
              stroke: "#41f486",
            },
            magenta: {
              fill: "rgba(255, 0, 255, 0.5)",
              stroke: "#ff00ff",
            },
            brown: {
              fill: "rgba(73, 34, 0, 0.5)",
              stroke: "#492200",
            },
          };

          // Define a default marker color
          let color = "blue";

          // Get the text from a feature
          var featureText = function (feature) {
            if (feature.get("text")) {
              return feature.get("text");
            } else {
              return "";
            }
          };

          let stroke = new Stroke({
            color: styleColors[color] ? styleColors[color].stroke : "#0033ff",
            width: 1,
          });

          // Special styles for the selected feature
          if (feature.get("selected")) {
            stroke.setWidth(5);
            stroke.setLineDash([5, 10]);
            stroke.setColor("blue");
          }

          // Return the proper feature style
          let style = new Style({
            fill: new Fill({
              color: styleColors[color]
                ? styleColors[color].fill
                : "rgba(0, 51, 255, 0.5)",
            }),
            stroke: stroke,
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: styleColors[color]
                  ? styleColors[color].fill
                  : "rgba(0, 51, 255, 0.5)",
              }),
              stroke: stroke,
            }),
            text: new Text({
              text: featureText(feature),
              textAlign: "center",
              offsetY: 40,
              scale: 1.4,
              stroke: new Stroke({
                color: "#ffffff",
                width: 3,
              }),
              fill: new Fill({
                color: styleColors[color].stroke,
              }),
            }),
          });

          return style;
        });

        this.$store.getters.getMap.addLayer(this.layers.highlightLayer);
      }

      return this.layers.highlightLayer;
    },
  },
};
</script>
