<template>
  <div class="container-fluid">
    <div class="row g-3 p-2">
      <!-- Left Menu: ElementRefinements -->
      <div class="col-12 col-md-3 col-lg-2">
        <Card class="shadow-sm">
          <template #content>
            <ElementRefinements
              :filters="filters"
              @filter-change="handleFilterChange"
            />
          </template>
        </Card>
      </div>

      <div class="col-12 col-md-9 col-lg-10">
        <Card class="shadow-sm">
          <template #content>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <ElementButtons :organisation="organisation" />
              </div>
              <div class="d-flex align-items-center">
                <InputGroup>
                  <InputText
                    v-model="search"
                    placeholder="Zoeken op LSN"
                    @input="updateSearch"
                    class="p-inputtext-lg"
                  />
                  <InputGroupAddon>
                    <strong>{{ pagination.total }}</strong
                    >&nbsp;resultaten
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </template>
        </Card>

        <!-- Table Section -->
        <Card class="shadow-sm mt-3">
          <template #content>
            <ElementTable
              :data="data"
              :pagination="pagination"
              :organisation="organisation"
              @pagination-changed="handlePaginationChange"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>


  
<script>
import { provide } from "vue";
import axios from "axios";
import ElementTable from "./ElementTable.vue";
import ElementButtons from "./ElementButtons.vue";
import ElementRefinements from "./ElementRefinements.vue";
import { Card, InputGroup, InputGroupAddon, InputText } from "primevue";

export default {
  components: {
    ElementRefinements,
    ElementButtons,
    InputText,
    ElementTable,
    Card,
    InputGroup,
    InputGroupAddon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      filters: [],
      element: [],
      data: [],
      pagination: {
        current_page: 0,
        last_page: 1,
        per_page: 10,
        total: 0,
        sort: undefined,
      },
    };
  },
  mounted() {
    this.getFilters();
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axios.get(elementsGetTableUrl, {
        params: {
          page: this.pagination.current_page,
          per_page: this.pagination.per_page,
          search: this.search,
          sort: JSON.stringify(this.pagination.sort),
        },
      });

      this.data = response.data.data;
      this.pagination = {
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        per_page: +response.data.per_page,
        total: response.data.total,
      };
    },
    getFilters() {
      try {
        axios.get(elementsGetFiltersUrl).then((response) => {
          console.log(response);
          this.filters = response.data;
        });
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    },
    async postFilters() {
      const response = await axios.post(elementsPostFiltersUrl, {
        filters: this.filters,
      });

      this.getData();
      // Get new filters to update the count values,
      this.getFilters();

      console.log(response);
    },
    updateSearch() {
      this.getData();
    },
    handleFilterChange() {
      console.log("Nieuwe filters!", this.filters);
      this.postFilters();
    },
    handlePaginationChange({ page, perPage, sort }) {
      // Werk paginering bij met nieuwe waarden
      if (page) this.pagination.current_page = page;
      if (perPage) this.pagination.per_page = perPage;
      if (sort) this.pagination.sort = sort;
      this.getData();
    },
  },
  setup(props) {
    // Functie om permissies te controleren
    function checkAuthorization(perms) {
      for (const perm of perms) {
        for (const role of props.user.roles) {
          if (role.permissions.includes(perm)) {
            return true;
          }
        }
      }
      return false;
    }

    // Maak de checkAuthorization-functie beschikbaar via provide
    provide("checkAuthorization", checkAuthorization);

    return {
      user: props.user,
    };
  },
};
</script>

<style scoped>
/* Component-specific styles */
</style>
  