<template>
  <div
    class="tools position-absolute top-0 end-0 mt-3 me-3 d-flex flex-column align-items-end"
    style="z-index: 10"
  >
    <div v-if="$store.getters.canCreate" class="d-flex mb-2">
      <create-component />
    </div>

    <!-- GPS Button -->
    <Button
      icon="pi pi-map-marker"
      :class="{
        active: $store.getters.activeTool === 'geolocation',
      }"
      @click="toggleGPS"
      aria-label="GPS Inschakelen"
      style="background-color: white"
      class="p-button-outlined mb-2"
      v-tooltip.left="{ value: 'GPS Inschakelen' }"
    ></Button>

    <!-- Info Selection Group -->
    <div class="d-flex mb-2">
      <Button
        :class="{
          active: $store.getters.activeTool === 'infoPolygon',
        }"
        @click="multiInfo('Polygon')"
        aria-label="Polygoon selectie"
        style="background-color: white"
        class="p-button-outlined me-2"
        v-tooltip.left="{ value: 'Polygoon selectie' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="currentColor"
          class="bi bi-info-square"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          />
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
          />
        </svg>
      </Button>
      <Button
        icon="pi pi-info-circle"
        :class="{
          active: $store.getters.activeTool === 'infoCircle',
        }"
        @click="multiInfo('Circle')"
        aria-label="Cirkel selectie"
        style="background-color: white"
        class="p-button-outlined"
        v-tooltip.left="{ value: 'Cirkel selectie' }"
      ></Button>
    </div>

    <!-- Redline Drawing Group -->
    <div class="d-flex mb-2">
      <Button
        icon="pi pi-pencil"
        :class="{
          active: $store.getters.activeTool === 'redlineLineString',
        }"
        @click="redline('LineString')"
        aria-label="Lijn tekenen"
        style="background-color: white"
        class="p-button-outlined me-2"
        v-tooltip.left="{ value: 'Lijn tekenen' }"
      ></Button>
      <Button
        :class="{
          active: $store.getters.activeTool === 'redlinePolygon',
        }"
        @click="redline('Polygon')"
        aria-label="Polygoon tekenen"
        style="background-color: white"
        class="p-button-outlined me-2"
        v-tooltip.left="{ value: 'Polygoon tekenen' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="currentColor"
          class="bi bi-info-square"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          />
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
          />
        </svg>
      </Button>
      <Button
        icon="pi pi-trash"
        @click="deleteRedline"
        aria-label="Verwijder redlining"
        style="background-color: white"
        class="p-button-outlined"
        v-tooltip.left="{ value: 'Verwijder redlining' }"
      ></Button>
    </div>

    <!-- Zoom Button -->
    <Button
      icon="pi pi-expand"
      @click="zoomToExtent"
      aria-label="Zoom naar start kaartbeeld"
      style="background-color: white"
      class="p-button-outlined mb-2"
      v-tooltip.left="{ value: 'Zoom naar start kaartbeeld' }"
    ></Button>

    <!-- Coordinate Button -->
    <Button
      icon="pi pi-compass"
      :class="{
        active: $store.getters.activeTool === 'getcoordinate',
      }"
      @click="getCoordinate"
      aria-label="Klik op de kaart voor coordinaten"
      style="background-color: white"
      class="p-button-outlined mb-2"
      v-tooltip.left="{ value: 'Klik op de kaart voor coordinaten' }"
    ></Button>

    <!-- Measurement Tools Group -->
    <div class="d-flex">
      <Button
        icon="pi pi-ruler"
        :class="{
          active: $store.getters.activeTool === 'measureLineString',
        }"
        @click="measuring('LineString')"
        aria-label="Lengte meten"
        style="background-color: white"
        class="p-button-outlined me-2"
        v-tooltip.left="{ value: 'Lengte meten' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="currentColor"
          class="bi bi-rulers"
          viewBox="0 0 16 16"
        >
          <path
            d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"
          />
        </svg>
      </Button>
      <Button
        label="m²"
        :class="{
          active: $store.getters.activeTool === 'measurePolygon',
        }"
        @click="measuring('Polygon')"
        aria-label="Oppervlakte meten"
        style="background-color: white"
        class="p-button-outlined me-2"
        v-tooltip.left="{ value: 'Oppervlakte meten' }"
      ></Button>
      <Button
        icon="pi pi-trash"
        @click="clearMeaureLayer"
        aria-label="Verwijder meetlijnen"
        style="background-color: white"
        class="p-button-outlined"
        v-tooltip.left="{ value: 'Verwijder meetlijnen' }"
      ></Button>
    </div>
  </div>
</template>


<style scoped lang="scss">
.active {
  background-color: var(--p-button-outlined-primary-color) !important;
  border-color: var(--p-button-outlined-primary-color) !important;
  color: #fff !important;
}
</style>

<script>
import { unByKey } from "ol/Observable";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Draw from "ol/interaction/Draw";
import { Snap, Modify } from "ol/interaction";
import { shiftKeyOnly } from "ol/events/condition";
import GeoJSON from "ol/format/GeoJSON";
import { Circle, Fill, Stroke, Style, Text } from "ol/style";
import Geolocation from "ol/Geolocation";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { containsExtent, boundingExtent } from "ol/extent";
import { fromCircle } from "ol/geom/Polygon";
import Overlay from "ol/Overlay";
import { Polygon, LineString } from "ol/geom";

// PrimeVue Button component
import Button from "primevue/button";
import CreateComponent from "./CreateComponent.vue";

export default {
  name: "ToolsComponent",
  components: {
    Button,
    CreateComponent,
  },
  props: {
    organisation: Object,
  },
  data() {
    return {
      geolocation: undefined,
      positionFeature: undefined,
      overlayLayer: undefined,
      draw: { modifyDraw: undefined, snap: undefined },
      drawLayer: undefined,
      measure: { modify: undefined, snap: undefined },
      measureLayer: undefined,
      redlineText: "",
      measureTooltipElement: undefined,
      measureTooltip: undefined,
      sketch: undefined,
    };
  },
  mounted() {
    const source = new VectorSource({ wrapX: false });
    this.overlayLayer = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({ color: "rgba(255, 186, 25, 0.5)" }),
        stroke: new Stroke({ color: "#ffba19", width: 5 }),
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: "rgba(255, 186, 25, 0.5)" }),
          stroke: new Stroke({ color: "#ffba19", width: 5 }),
        }),
      }),
    });

    this.drawLayer = new VectorLayer({
      title: "DrawLayer",
      source: new VectorSource({ wrapX: false }),
    });
    this.drawLayer.setStyle((feature) => {
      return new Style({
        stroke: new Stroke({ color: "rgb(29, 1, 139)", width: 2 }),
        fill: new Fill({ color: "rgba(247, 113, 73, 0.4)" }),
        text: new Text({
          text: feature.get("text") || "",
          font: "30px sans-serif",
          fill: new Fill({ color: "#000" }),
          stroke: new Stroke({ color: "#fff", width: 10 }),
        }),
      });
    });

    this.measureLayer = new VectorLayer({
      title: "MeasureLayer",
      source: new VectorSource(),
    });
    this.measureLayer.setStyle((feature) => {
      return new Style({
        fill: new Fill({ color: "rgba(255, 255, 255, 0.2)" }),
        stroke: new Stroke({ color: "#ffcc33", width: 2 }),
        image: new Circle({ radius: 7, fill: new Fill({ color: "#ffcc33" }) }),
        text: new Text({
          text: feature.get("text") || "",
          textAlign: "center",
          offsetY: 40,
          scale: 1.4,
          overflow: true,
          stroke: new Stroke({ color: "#ffcc33", width: 3 }),
          fill: new Fill({ color: "#000" }),
        }),
      });
    });

    this.$store.getters.getMap.addLayer(this.overlayLayer);
    this.$store.getters.getMap.addLayer(this.measureLayer);
    this.$store.getters.getMap.addLayer(this.drawLayer);
  },
  methods: {
    toggleGPS() {
      if (this.$store.getters.activeTool !== "geolocation") {
        this.$store.commit("activeTool", "geolocation");
      } else {
        this.$store.commit("activeTool", undefined);
        this.removeInteractions();
      }
      if (!this.geolocation) {
        this.geolocation = new Geolocation({
          projection: this.$store.getters.getMap.getView().getProjection(),
          trackingOptions: { enableHighAccuracy: true, maximumAge: 2000 },
        });
      }
      this.geolocation.setTracking(
        this.$store.getters.activeTool === "geolocation"
      );
      if (this.$store.getters.activeTool === "geolocation") {
        this.positionFeature = new Feature();
        this.positionFeature.setStyle(
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({ color: "#3399CC" }),
              stroke: new Stroke({ color: "#fff", width: 2 }),
            }),
          })
        );
        this.geolocation.on("change:position", () => {
          const coordinates = this.geolocation.getPosition();
          this.positionFeature.setGeometry(
            coordinates ? new Point(coordinates) : null
          );
          const mapSize = this.$store.getters.getMap.getSize();
          const mapExtent = this.$store.getters.getMap
            .getView()
            .calculateExtent(mapSize);
          const mapCenter = this.$store.getters.getMap.getView().getCenter();
          const leftRightBound = (mapExtent[0] - mapCenter[0]) * 0.3;
          const topBottomBound = (mapExtent[1] - mapCenter[1]) * 0.3;
          const safeMapExtent = [
            mapExtent[0] - leftRightBound,
            mapExtent[1] - topBottomBound,
            mapExtent[2] + leftRightBound,
            mapExtent[3] + topBottomBound,
          ];
          if (
            !containsExtent(
              safeMapExtent,
              this.positionFeature.getGeometry().getExtent()
            )
          ) {
            this.$store.getters.getMap
              .getView()
              .animate({ center: coordinates });
          }
        });
        this.overlayLayer.getSource().addFeature(this.positionFeature);
      } else {
        this.overlayLayer.getSource().removeFeature(this.positionFeature);
        this.positionFeature = undefined;
      }
    },
    redline(type) {
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      if ("redline" + type === this.$store.getters.activeTool) {
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        this.draw = { modifyDraw: undefined, snap: undefined };
        if (this.$store.getters.activeTool === "geolocation")
          this.$store.commit("activeTool", undefined);
        return;
      }
      this.removeInteractions();
      this.$store.commit("newInteraction", undefined);
      this.draw = { modifyDraw: undefined, snap: undefined };
      this.$store.commit("activeTool", "redline" + type);
      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.drawLayer.getSource(),
          type: type,
          freehandCondition: shiftKeyOnly,
        })
      );
      this.draw.modifyDraw = new Modify({ source: this.drawLayer.getSource() });
      this.$store.getters.getMap.addInteraction(this.draw.modifyDraw);
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
      this.draw.snap = new Snap({ source: this.drawLayer.getSource() });
      this.$store.getters.getMap.addInteraction(this.draw.snap);
      this.$store.getters.interaction.on("drawend", (event) => {
        // Additional processing can be done here.
      });
    },
    multiInfo(action) {
      if ("info" + action === this.$store.getters.activeTool) {
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.removeInteractions();
        this.$store.commit("activeTool", undefined);
        this.feature = undefined;
        this.draw = { modifyDraw: undefined, snap: undefined };
        return;
      }
      this.$store.commit("activeTool", "info" + action);
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("newInteraction", undefined);
      this.$store.commit("newInteraction", new Draw({ type: action }));
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
      this.$store.getters.interaction.on("drawend", (evt) => {
        if (action === "Circle") {
          evt.feature.setGeometry(fromCircle(evt.feature.getGeometry()));
        }
        this.$store.commit(
          "clickEvent",
          new GeoJSON().writeFeatureObject(evt.feature).geometry
        );
        this.$store.commit("setActiveTab", "Info");
      });
    },
    zoomToExtent() {
      let extent = this.organisation.map_config.extent;
      if (extent.constructor === Array) {
        extent = boundingExtent(extent);
      } else {
        const geojson = new GeoJSON();
        const feature = geojson.readFeature(extent);
        extent = feature.getGeometry().getExtent();
      }
      this.$store.getters.getMap
        .getView()
        .fit(extent, this.$store.getters.getMap.getSize());
    },
    deleteRedline() {
      this.$store.commit("activeTool", undefined);
      this.removeInteractions();
      this.drawLayer.getSource().clear();
      this.draw = {
        interaction: undefined,
        modifyDraw: undefined,
        snap: undefined,
      };
      if (!this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOn");
    },
    getCoordinate() {
      if (this.$store.getters.activeTool !== "getcoordinate") {
        if (this.$store.getters.infoClickState) {
          this.$store.dispatch("toggleClickOff");
        }

        this.$store.commit("activeTool", "getcoordinate");
        this.removeInteractions();
        this.$store.commit(
          "newInteraction",
          this.$store.getters.getMap.on("singleclick", (event) => {
            if (this.$store.getters.activeTool === "getcoordinate") {
              const x = Math.round(event.coordinate[0] * 100) / 100;
              const y = Math.round(event.coordinate[1] * 100) / 100;
              const coordinate = x + " , " + y;
              this.$toast.add({
                severity: "info",
                summary: "Coordinaat",
                detail: coordinate,
              });
            }
          })
        );
      } else {
        this.$store.commit("activeTool", undefined);
        this.removeInteractions();
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
      }
    },
    measuring(format) {
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      if ("measure" + format === this.$store.getters.activeTool) {
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.removeInteractions();
        this.measure = { modify: undefined, snap: undefined };
        if (this.$store.getters.activeTool === "measure" + format)
          this.$store.commit("activeTool", undefined);
        return;
      }
      this.removeInteractions();
      this.measure = { modify: undefined, snap: undefined };
      this.$store.commit("activeTool", "measure" + format);
      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.measureLayer.getSource(),
          type: format,
          freehandCondition: shiftKeyOnly,
        })
      );
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
      let output;
      this.createMeasureTooltip();
      let listener;
      this.$store.getters.interaction.on("drawstart", (event) => {
        this.sketch = event.feature;
        let tooltipCoord = event.coordinate;
        listener = this.sketch.getGeometry().on("change", (evt) => {
          const geom = evt.target;
          if (geom instanceof Polygon) {
            const area = geom.getArea();
            if (area != null) {
              output = Math.round(area * 100) / 100 + " m²";
            }
          } else if (geom instanceof LineString) {
            const length = Math.round(geom.getLength() * 100) / 100;
            if (length != null) {
              output = Math.round(length * 100) / 100 + " m";
            }
          }
          this.measureTooltipElement.innerHTML = output;
          this.measureTooltip.setPosition(tooltipCoord);
        });
      });
      this.$store.getters.interaction.on("drawend", (event) => {
        this.sketch = null;
        event.feature.set("text", output);
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
        this.measureTooltipElement = null;
        this.createMeasureTooltip();
        unByKey(listener);
      });
    },
    clearMeaureLayer() {
      this.measureLayer.getSource().clear();
    },
    createMeasureTooltip() {
      if (this.measureTooltipElement) {
        this.measureTooltipElement.parentNode.removeChild(
          this.measureTooltipElement
        );
      }
      const positioning = "bottom-center";
      this.measureTooltipElement = document.createElement("div");
      this.measureTooltipElement.className = "tooltip tooltip-measure";
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning,
      });
      this.$store.getters.getMap.addOverlay(this.measureTooltip);
    },
    removeInteractions() {
      if (this.$store.getters.interaction) {
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        if (this.$store.getters.interaction.listener) {
          this.$store.getters.getMap.un(
            this.$store.getters.interaction.type,
            this.$store.getters.interaction.listener
          );
        }
        this.$store.commit("newInteraction", undefined);
      }
    },
  },
};
</script>
