<template>
  <Card class="mt-4">
    <template #content>
      <div class="row">
        <h4 class="col-md-9">Werkbeschrijving</h4>

        <div
          class="col-md-3 float-end"
          v-if="hasPermission(['admin', 'user', 'upload_brief'])"
        >
          <FileUpload
            mode="basic"
            :url="'/elementen/' + this.element.elementtype.id + '/brief_pdf'"
            name="file"
            :maxFileSize="10000000"
            @upload="onUpload"
            :auto="true"
            chooseLabel="Uploaden"
          />
        </div>
      </div>

      <div class="row">
        <b class="col-md-5">Bestandsnaam</b>
        <b class="col-md-5">Bestandsgrootte</b>
      </div>
      <div class="row" v-for="file in files" v-bind:key="file.id">
        <p class="col-md-5">
          <Button :label="file.name" variant="link" @click="download(file)" />
        </p>
        <p class="col-md-5">{{ file.size }}</p>
        <p
          class="col-md-2"
          v-if="hasPermission(['admin', 'user', 'delete_brief'])"
          @click="deleteFile(file)"
        >
          <i class="pi pi-times"></i>
        </p>
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, computed, inject } from "vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import InputNumber from "primevue/inputnumber";
import axios from "axios";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import { Card } from "primevue";

export default {
  name: "Files",
  components: {
    AutoComplete,
    Dropdown,
    InputText,
    InputNumber,
    FileUpload,
    Button,
    Card,
  },
  data: () => ({
    files: [],
  }),
  props: {
    element: Object,
    element_data: Object,
    organisation: Object,
    user: Object,
  },
  mounted() {
    console.log(this.element_data);
    this.setup();
  },
  methods: {
    setup() {
      this.getFiles();
    },

    getFiles() {
      try {
        axios
          .get("/elementen/" + this.element.elementtype.id + "/brief_pdf")
          .then((response) => {
            console.log(response);
            this.files = response.data;
          });
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    },

    onUpload(event) {
      this.getFiles();
    },

    download(file) {
      const url =
        "/uploads/files/pdf/brief/el_type_" +
        this.organisation.id +
        "_" +
        this.element.elementtype.id +
        "/" +
        file.file_name;
      console.log(url);
      window.open(url);
    },

    deleteFile(file) {
      try {
        axios.delete("/elementen/brief_pdf/" + file.id).then((response) => {
          console.log(response);
          this.getFiles();
        });
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    },

    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
  },
};
</script>

<style scoped>
/* Scoped styles specific to this component */
</style>
