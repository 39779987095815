<template>
  <Card class="position-relative">
    <template #content>
      <!-- Map container with fixed height -->
      <div
        ref="overview-map-root"
        id="overview-map-root"
        style="height: 300px"
      ></div>

      <!-- Edit buttons container positioned using Bootstrap utilities -->
      <div
        v-if="permission"
        class="position-absolute"
        style="top: 25px; right: 25px; z-index: 999"
      >
        <!-- Edit Icon Button using PrimeVue -->
        <div class="mb-2">
          <Button
            v-if="!edit"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-secondary p-button-sm"
            @click="editFeature"
            aria-label="Bewerk"
          />
        </div>

        <!-- Save button remains as a Bootstrap button -->
        <div class="mb-2">
          <button
            v-if="edit"
            class="btn btn-success btn-sm"
            @click="processRequest"
          >
            Opslaan
          </button>
        </div>

        <!-- Cancel button remains as a Bootstrap button -->
        <div>
          <button v-if="edit" class="btn btn-secondary btn-sm" @click="cancel">
            Annuleren
          </button>
        </div>
      </div>
    </template>
  </Card>
</template>


<!-- <style>
#overview-map-root {
  height: 300px;
}
.ol-viewport {
  position: absolute;
}
.edit-buttons {
  position: absolute;
  right: 38px;
  top: 25px;
  margin: auto;
  z-index: 999;
}
</style> -->

<script>
import View from "ol/View";
import Map from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import * as OlSource from "ol/source";
import * as OlLayers from "ol/layer";
import Point from "ol/geom/Point";
import { register } from "ol/proj/proj4";
import Projection from "ol/proj/Projection";
import proj4 from "proj4";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import Modify from "ol/interaction/Modify";

import axios from "axios";
import { Button, Card } from "primevue";

export default {
  name: "OverviewMap",
  components: {
    Card,
    Button,
  },
  props: {
    features: Object,
    organisation: Object,
    user: Object,
  },
  data() {
    return {
      permission: false,
      edit: false,
      map: Object,
      featureLayer: Object,
      modify: Object,
      style: (feature) => {
        const styles = [
          new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "#4caf50",
              width: 2,
            }),
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: "#4caf50",
              }),
            }),
          }),
        ];
        console.log(feature.get("element_type"));
        // Only display for some element types
        if ([133, 134, 46, 71].includes(feature.get("element_type"))) {
          const geometry = feature.getGeometry();

          if (geometry.getType() === "LineString") {
            // Verwerk een enkele LineString
            let dx, dy;
            geometry.forEachSegment((start, end) => {
              dx = end[0] - start[0];
              dy = end[1] - start[1];
            });

            const rotation = Math.atan2(dy, dx);
            styles.push(
              new Style({
                geometry: new Point(geometry.getLastCoordinate()),
                image: new Icon({
                  src: window.location.origin + "/assets/arrow.png",
                  anchor: [0.75, 0.5],
                  rotateWithView: true,
                  rotation: -rotation,
                }),
              })
            );
          } else if (geometry.getType() === "MultiLineString") {
            // Verwerk een MultiLineString
            const lineStrings = geometry.getLineStrings(); // Haal alle LineString-objecten op
            lineStrings.forEach((lineString) => {
              let dx, dy;
              lineString.forEachSegment((start, end) => {
                dx = end[0] - start[0];
                dy = end[1] - start[1];
              });

              const rotation = Math.atan2(dy, dx);
              styles.push(
                new Style({
                  geometry: new Point(lineString.getLastCoordinate()),
                  image: new Icon({
                    src: window.location.origin + "/assets/arrow.png",
                    anchor: [0.75, 0.5],
                    rotateWithView: true,
                    rotation: -rotation,
                  }),
                })
              );
            });
          } else {
            console.warn("Unsupported geometry type:", geometry.getType());
          }
        }

        return styles;
      },
    };
  },

  mounted() {
    const features = new GeoJSON().readFeatures(this.features);
    // a new vector layer is created with the feature
    this.featureLayer = new OlLayers.Vector({
      source: new OlSource.Vector({
        features,
      }),
      style: this.style,
    });

    // Default extent if there is no feature extent
    const extent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];

    // Dutch projection
    const projection = new Projection({
      code: "EPSG:28992",
      units: "m",
      extent,
    });
    proj4.defs(
      "EPSG:28992",
      "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs"
    );
    proj4.defs("urn:x-ogc:def:crs:EPSG:28992", proj4.defs("EPSG:28992"));
    proj4.defs(
      "http://www.opengis.net/gml/srs/epsg.xml#28992",
      proj4.defs("EPSG:28992")
    ); // Used by geoserver
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    register(proj4);

    this.map = new Map({
      target: this.$refs["overview-map-root"],
      layers: [
        // Default opelayers map
        new OlLayers.Image({
          source: new OlSource.ImageWMS({
            url: "https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0",
            params: {
              LAYERS: ["2021_orthoHR"],
              FORMAT: "image/png",
              TRANSPARENT: true,
              VERSION: "1.1.1",
              TILED: false,
            },
          }),
        }),
        // Street names
        new OlLayers.Image({
          source: new OlSource.ImageWMS({
            url: "https://mapserver.gisarts.nl?map=/etc/mapserver/gisarts.map",
            params: {
              LAYERS: ["bgt_openbareruimtelabel_lijngericht"],
              FORMAT: "image/png",
              TRANSPARENT: true,
              VERSION: "1.1.1",
              TILED: false,
            },
          }),
        }),
        this.featureLayer,
      ],

      view: new View({
        projection,
        center: [197800, 454050],
        zoom: 5,
      }),
    });

    // Check if the user is allowed to edit
    if (this.user) {
      this.user.roles.forEach((role) => {
        const r = JSON.parse(role.permissions);
        console.log(r);
        if ((r.edit_element_general && this.organisation.id !== 4) || r.admin) {
          this.permission = true;
        }
      });
    }

    // Zoom to element
    if (this.featureLayer.getSource().getExtent()) {
      this.map
        .getView()
        .fit(this.featureLayer.getSource().getExtent(), { minResolution: 1 });
    }
  },
  methods: {
    cancel() {
      this.edit = !this.edit;
      this.map.removeInteraction(this.modify);

      this.featureLayer.getSource().clear();
      this.featureLayer
        .getSource()
        .addFeatures(new GeoJSON().readFeatures(this.features));

      this.featureLayer.setStyle(this.style);
    },

    editFeature() {
      const style = new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      });

      this.featureLayer.setStyle(style);
      if (this.modify) {
        this.map.removeInteraction(this.modify);
      }

      // Now allow the user to change the selected vector and activate the interaction tool
      this.modify = new Modify({
        source: this.featureLayer.getSource(),
        // features: this.features,
        style,
        // delete vertices by pressing the SHIFT key
        // deleteCondition: function(event) {
        //     return ol.events.condition.shiftKeyOnly(event) &&
        //     ol.events.condition.singleClick(event);
        // }
      });

      // add it to the map
      this.map.addInteraction(this.modify);

      this.edit = !this.edit;
    },

    /**
     * Create a insert, update or delete request to the server and send it to the server
     */
    processRequest() {
      const feature = this.featureLayer.getSource().getFeatures()[0];
      const element = feature.getProperties();

      let geom = feature.getGeometry();

      if (geom.getType() === "LineString") {
        let length = Math.round(geom.getLength());
        if (element.length) {
          element.length = length;
        }
      } else if (geom.getType() === "Polygon") {
        let area = Math.round(geom.getArea());
        if (element.area) {
          element.area = area;
        }
      }

      element.geom = new GeoJSON().writeFeatureObject(feature).geometry;

      this.sendRequest(element).then((response) => {
        this.edit = false;
        this.featureLayer.setStyle(this.style);
        this.map.removeInteraction(this.modify);
      });
    },

    sendRequest(data) {
      let headers = {};
      return axios({
        method: "post",
        url: elementsUpdateUrl,
        data,
      });
    },
  },
};
</script>
