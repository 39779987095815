<template>
  <Card class="mt-2">
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="m-0">Onderhoud</h4>
        <template
          v-if="
            (hasPermissions(['super', 'admin', 'user', 'add_maintenance']) &&
            organisation.id === 4
              ? element.owners.some((o) => o.id === user.owner_id)
              : true) || hasPermissions(['super', 'admin'])
          "
        >
          <Button
            label="Maatregel toevoegen"
            class="p-button-success"
            icon="pi pi-plus"
            @click="addMaintenance"
          />
        </template>
      </div>
    </template>
    <template #content>
      <Accordion :value="currentOpen" v-if="maintenanceList.length > 0">
        <AccordionPanel
          v-for="(maint, i) in maintenanceList"
          :value="i"
          :key="maint.id"
        >
          <AccordionHeader :class="maint.status.maintenance_status">
            {{ maint.maintenancetype.maintenance_type_nl }},
            {{ maint.status.maintenance_status_nl }},
            {{ maint.calculation_value }} {{ maint.unit.unit }}
            <br />
            {{ maint?.executed_by?.executed_by }}
          </AccordionHeader>
          <AccordionContent>
            <div
              class="row float-end"
              v-if="
                hasPermissions(['admin', 'user', 'edit_remove_maintenance']) &&
                (organisation.id === 4
                  ? element.owners.some((o) => o.id === user.owner_id)
                  : true)
              "
            >
              <Button
                v-if="!maint.editing"
                icon="pi pi-pencil"
                variant="text"
                rounded
                @click="editMaintenance(maint)"
              />
            </div>

            <div class="row">
              <div class="col-md-6" :class="maint.status">
                <b>Status wijzigen</b>
                <br />
                <Select
                  v-model="maint.status"
                  :options="getFilteredStatuses(maint)"
                  placeholder=""
                  optionLabel="maintenance_status_nl"
                  @change="(event) => updateStatus(event, maint, index)"
                  class=""
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex items-center">
                      <div>{{ slotProps.value.maintenance_status_nl }}</div>
                    </div>
                  </template>
                </Select>
              </div>
              <template v-if="maint.editing">
                <div class="col-md-6">
                  <b>Type</b>
                  <br />
                  <AutoComplete
                    v-model="maint.maintenancetype"
                    dropdown
                    optionLabel="maintenance_type_nl"
                    :suggestions="suggestions['typesArr']"
                    @complete="search($event, 'typesArr')"
                  />
                </div>
                <div class="col-md-6">
                  <b>Periode vanaf</b>
                  <div>
                    <DatePicker v-model="maint.period_from" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <b>Periode tot</b>
                  <div>
                    <DatePicker
                      :minDate="new Date(maint.period_from) ?? null"
                      v-model="maint.period_to"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <b>Rekenwaarde</b>
                  <div>
                    <InputText
                      v-model="maint.calculation_value"
                      style="width: 25%"
                      required
                    />
                    <AutoComplete
                      v-model="maint.unit"
                      style="width: 50%"
                      optionLabel="unit"
                      dropdown
                      :suggestions="suggestions['unitsArr']"
                      @complete="search($event, 'unitsArr')"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <b>Cyclus</b>
                  <div>
                    <AutoComplete
                      v-model="maint.cycle"
                      dropdown
                      optionLabel="cycle_nl"
                      :suggestions="suggestions['cyclesArr']"
                      @complete="search($event, 'cyclesArr')"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <b>Gereedschap</b>
                  <br />

                  <AutoComplete
                    v-model="maint.tool"
                    dropdown
                    multiple
                    optionLabel="tool_nl"
                    :suggestions="suggestions['toolsArr']"
                    @complete="search($event, 'toolsArr')"
                  />
                </div>
                <div class="col-md-6">
                  <b>Fasering</b>
                  <br />

                  <AutoComplete
                    v-model="maint.phasing"
                    dropdown
                    optionLabel="phasing"
                    :suggestions="suggestions['phasingsArr']"
                    @complete="search($event, 'phasingsArr')"
                  />
                </div>
                <div class="col-md-6">
                  <b>Uitvoerder</b>
                  <br />

                  <AutoComplete
                    v-model="maint.executed_by"
                    dropdown
                    optionLabel="executed_by"
                    :suggestions="suggestions['companiesArr']"
                    @complete="search($event, 'companiesArr')"
                  />
                </div>
                <div class="col-md-6">
                  <b>Vrijgekomen materiaal</b>
                  <br />

                  <AutoComplete
                    v-model="maint.waste[0]"
                    dropdown
                    optionLabel="waste_nl"
                    :suggestions="suggestions['wastesArr']"
                    @complete="search($event, 'wastesArr')"
                  />
                </div>
                <div class="col-md-12">
                  <b>Verwerking</b>
                  <br />

                  <AutoComplete
                    v-model="maint.processing[0]"
                    dropdown
                    optionLabel="processing_nl"
                    :suggestions="suggestions['processingsArr']"
                    @complete="search($event, 'processingsArr')"
                  />
                </div>
              </template>
            </div>
            <div
              class="row mt-2"
              v-if="
                maint.editing &&
                hasPermissions(['user', 'maintenance_profits', 'admin'])
              "
            >
              <Divider />
              <div class="col-md-6">
                <b>Verwachte opbrengst</b>
                <br />
                <InputText v-model="maint.profit.expected_profit" required />
              </div>
              <div class="col-md-6">
                <b>Opbrengst</b>
                <br />

                <InputText v-model="maint.profit.profit" class="" required />
              </div>

              <div class="col-md-6">
                <b>Datum beschikbaar</b>
                <div>
                  <DatePicker v-model="maint.profit.available" required />
                </div>
              </div>
              <div class="col-md-6">
                <b>Datum opgehaald</b>
                <div>
                  <DatePicker v-model="maint.profit.pickup" required />
                </div>
              </div>
            </div>

            <Divider />

            <div
              v-if="maint.editing"
              class="row mt-2 d-flex justify-content-between"
            >
              <Button
                label="Verwijderen"
                class="p-button-danger col-md-3"
                icon="pi pi-trash"
                @click="deleteMaintenance(i, maint.id)"
              />

              <Button
                label="Annuleren"
                class="p-button-secondary col-md-3"
                icon="pi pi-times"
                @click="editMaintenance(maint)"
              />

              <Button
                label="Opslaan"
                class="p-button-success col-md-3"
                icon="pi pi-check"
                @click="saveMaintenance(maint)"
              />
            </div>
            <template v-if="!maint.editing">
              <div class="row">
                <div class="col-md-6">
                  <b>Periode vanaf</b>
                  <p>{{ formatDate(maint.period_from) }}</p>
                </div>
                <div class="col-md-6">
                  <b>Periode tot</b>
                  <p>{{ formatDate(maint.period_to) }}</p>
                </div>
                <div class="col-md-6">
                  <b>Rekenwaarde</b>
                  <p>{{ maint.calculation_value }} {{ maint.unit.unit }}</p>
                </div>
                <div class="col-md-6">
                  <b>Cyclus</b>
                  <p>{{ maint.cycle.cycle_nl }}</p>
                </div>
                <div class="col-md-6">
                  <b>Gereedschap</b>
                  <p>{{ maint.tool.map((item) => item.tool_nl).join(", ") }}</p>
                </div>
                <div class="col-md-6">
                  <b>Fasering</b>
                  <p>{{ maint.phasing.phasing }}</p>
                </div>
                <div class="col-md-6">
                  <b>Uitvoerder</b>
                  <p v-if="maint?.executed_by">
                    {{ maint.executed_by.executed_by }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Vrijgekomen materiaal</b>
                  <p>{{ maint.waste[0]?.waste_nl }}</p>

                  <!-- <p>{{ maint.waste.map((item) => item.waste).join(", ") }}</p> -->
                </div>
                <div class="col-md-6" v-if="maint.processing">
                  <b>Verwerking</b>
                  <p>{{ maint.processing[0]?.processing_nl }}</p>

                  <!-- <p>
                  {{
                    maint.processing.map((item) => item.processing).join(", ")
                  }}
                </p> -->
                </div>
              </div>

              <Divider />

              <template
                v-if="hasPermissions(['user', 'maintenance_profits', 'admin'])"
              >
                <div class="row">
                  <div class="col-md-6">
                    <b>Verwachte opbrengst</b>
                    <p>
                      {{ maint.profit ? maint.profit.expected_profit : "" }}
                      {{
                        maint.profit && maint.profit.expected_profit_unit
                          ? maint.profit.expected_profit_unit.unit
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <b>Opbrengst</b>
                    <p>
                      {{ maint.profit ? maint.profit.profit : "" }}
                      {{
                        maint.profit && maint.profit.expected_profit_unit
                          ? maint.profit.expected_profit_unit.unit
                          : ""
                      }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b>Datum beschikbaar</b>
                    <p>
                      {{
                        maint.profit ? formatDate(maint.profit.available) : ""
                      }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <b>Datum opgehaald</b>
                    <p>
                      {{ maint.profit ? formatDate(maint.profit.pickup) : "" }}
                    </p>
                  </div>
                </div>
              </template>

              <Accordion
                v-if="
                  hasPermissions(['admin', 'user', 'add_maintenance_notes'])
                "
              >
                <AccordionPanel>
                  <AccordionHeader> Notities </AccordionHeader>
                  <AccordionContent>
                    <NotesContent
                      :notes="maint.notes"
                      :elementId="element.id"
                      :maintenanceId="maint.id"
                      permissionForAdd="add_maintenance_notes"
                      @note-added="onNoteAdded"
                    />
                  </AccordionContent>
                </AccordionPanel>
              </Accordion>
            </template>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <div v-else>Geen onderhoudsgegevens beschikbaar.</div>
    </template>
  </Card>
</template>

<script>
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import DatePicker from "primevue/datepicker";
import { inject } from "vue";
import axios from "axios";

import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import AccordionTab from "primevue/accordiontab";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

import Select from "primevue/select";
import Button from "primevue/button";
import { toRaw } from "vue";
import NotesContent from "../NotesContent.vue";
import { Card, Divider } from "primevue";

export default {
  name: "Maintenance",
  components: {
    Dropdown,
    AutoComplete,
    DatePicker,
    Accordion,
    AccordionTab,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    InputNumber,
    InputText,
    Select,
    Button,
    NotesContent,
    Card,
    Divider,
  },
  props: {
    element: Object,
    organisation: Object,
    user: Object,
    element_data: Object,
  },
  data() {
    return {
      maintenanceList: this.element.maintenance.map((maint) => {
        if (!maint.phasing) maint.phasing = {};
        if (!maint.waste) maint.waste = {};
        if (!maint.profit) maint.profit = {};
        if (!maint.unit) maint.unit = {};

        // Voeg nieuwe eigenschappen toe aan 'maint'
        return {
          ...maint,
          editing: false, // Voeg hier de 'editing' eigenschap toe
        };
      }),
      suggestions: {},
      currentOpen: 0,
      inputCreateNoteText: "",
    };
  },
  mounted() {},
  methods: {
    hasPermissions(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    canEditStatus(id) {
      return true;
    },
    addMaintenance() {
      this.maintenanceList.push({
        editing: true,
        maintenancetype: {
          maintenance_type_nl: "",
        },
        status: {
          id: 1,
          maintenance_status: "planned",
          maintenance_status_nl: "Gepland",
        },
        period_from: new Date(),
        period_to: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        unit: {
          unit: "",
        },
        calculation_value: null,
        cycle: this.element_data["cyclesArr"][0],
        tool: [],
        phasing: {
          phasing: "",
        },
        waste: [],
        executed_by: {
          executed_by: "",
        },
        processing: [],
        profit: {
          expected_profit: "",
          expected_profit_unit: {
            unit: "",
          },
          profit: "",
          available: "",
          pickup: "",
        },
      });

      this.currentOpen = this.maintenanceList.length - 1;
    },
    editMaintenance(maint) {
      maint.editing = !maint.editing;
    },
    getFilteredStatuses(maint) {
      return this.element_data.statusesArr.filter((status) => {
        const hasRolePermission =
          maint?.executed_by &&
          this.user.roles.some((r) => r.id === 20) &&
          this.user.company_id === maint.executed_by.id;

        console.log("role", hasRolePermission);

        const hasOrganisationPermission =
          this.organisation.id === 4
            ? this.element.owners.some(
                (owner) => owner.id === this.user.owner_id
              )
            : true;

        return (
          this.hasPermissions(["admin", "super"]) ||
          (this.hasPermissions(["maintenance_" + status.maintenance_status]) &&
            (hasRolePermission || hasOrganisationPermission))
        );
      });
    },
    async saveMaintenance(maint) {
      maint.editing = !maint.editing;

      const body = {
        ...maint,
        element_id: this.element.id,
        maintenance_id: maint.id,
        cycle_id: maint.cycle.id,
        phasing_id: maint.phasing.id,
        executed_by_id: maint?.executed_by?.id,
        processing_id: maint.processing.id,
        maintenance_type_id: maint.maintenancetype.id,
      };

      console.log(body);
      await axios
        .post("/elementen/store/maintenance", body)
        .then((response) => {
          console.log(response);
        });
    },
    async deleteMaintenance(index, id) {
      this.maintenanceList.splice(index, 1);

      await axios
        .post("/elementen/remove/maintenance", { id })
        .then((response) => {
          console.log(response);
        });
    },
    async updateStatus(event, maint) {
      const body = {
        element_id: this.element.id,
        maintenance_id: maint.id,
        status: event.value.maintenance_status,
      };
      await axios
        .post("/elementen/store/maintenance_status", body)
        .then((response) => {
          console.log(response);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();

      const keysToCheck = [
        "tool_nl",
        "unit",
        "phasing",
        "waste_nl",
        "executed_by",
        "cycle_nl",
        "processing_nl",
        "maintenance_type_nl",
      ];

      // Filter de array
      const filtered = Object.values(this.element_data[fieldName]).filter(
        (item) => {
          if (typeof item === "string") {
            return item.toLowerCase().includes(query);
          } else if (typeof item === "object" && item !== null) {
            // Controleer of een van de sleutels in het object voorkomt
            return keysToCheck.some(
              (key) => key in item && item[key].toLowerCase().includes(query)
            );
          }
          return false;
        }
      );

      // Zet de gefilterde resultaten in de juiste veld-sleutel binnen `suggestions`
      this.suggestions[fieldName] = filtered;
    },
    async addNote(maint) {
      try {
        const response = await axios.post("/note/create", {
          element_id: this.element.id,
          maintenance_id: maint.id,
          note: this.inputCreateNoteText,
        });

        if (!maint.notes) {
          maint.notes = [];
        }

        maint.notes.unshift(response.data);

        this.inputCreateNoteText = "";

        this.$forceUpdate();
      } catch (error) {
        console.error("Error adding maintenance note:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../scss/maintenace_statuses.scss";
</style>
