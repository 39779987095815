<template>
  <div class="d-flex flex-wrap gap-2">
    <Button
      icon="pi pi-globe"
      class="btn btn-outline-primary btn-sm"
      @click="navigateTo('/kaart/elementen')"
      tooltip="Selectie overzetten naar de kaart"
      tooltipOptions="{ position: 'top' }"
    />

    <Button
      v-if="hasPermission(['admin', 'user', 'export'])"
      icon="pi pi-file-excel"
      class="btn btn-outline-success btn-sm"
      @click="navigateTo('/elementen/excel-export')"
      tooltip="Exporteren"
      tooltipOptions="{ position: 'top' }"
    />

    <Button
      v-if="hasPermission(['admin', 'user', 'export'])"
      icon="pi pi-download"
      class="btn btn-outline-info btn-sm"
      @click="navigateTo('/elementen/shape-export')"
      tooltip="Exporteren"
      tooltipOptions="{ position: 'top' }"
    />

    <Button
      icon="pi pi-print"
      class="btn btn-outline-secondary btn-sm"
      @click="navigateTo('/elementen/print')"
      tooltip="Print maken"
      tooltipOptions="{ position: 'top' }"
    />
  </div>
</template>

<script>
import { inject } from "vue";
import ToggleSwitch from "primevue/toggleswitch";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Select from "primevue/select";

export default {
  components: {
    ToggleSwitch,
    Dialog,
    Button,
    Select,
  },
  emits: ["update-table", "update:modelValue"],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const checkAuthorization = inject("checkAuthorization");

    const hasPermission = (perms) => {
      return checkAuthorization?.(perms);
    };

    const navigateTo = (path) => {
      window.open(path, "_blank");
    };

    return {
      hasPermission,
      navigateTo,
    };
  },
};
</script>
  
<style scoped>
</style>
  