<template>
  <!-- Create Drawing Tools Container using a flex container -->
  <Button
    icon="pi pi-pencil"
    :class="{
      active: $store.getters.activeTool === 'CreateLineString',
    }"
    class="p-button-outlined me-2"
    @click="startDraw('LineString')"
    style="background-color: white"
    aria-label="Nieuw lijnelement tekenen"
    v-tooltip.left="{ value: 'Nieuw lijnelement tekenen' }"
  />
  <Button
    icon="pi pi-pencil"
    :class="{
      active: $store.getters.activeTool === 'CreatePolygon',
    }"
    class="p-button-outlined me-2"
    @click="startDraw('Polygon')"
    style="background-color: white"
    aria-label="Nieuw vlakelement tekenen"
    v-tooltip.left="{ value: 'Nieuw vlakelement tekenen' }"
  />
  <Button
    icon="pi pi-circle-fill"
    :class="{ active: $store.getters.activeTool === 'CreatePoint' }"
    class="p-button-outlined"
    @click="startDraw('Point')"
    style="background-color: white"
    aria-label="Nieuw puntelement tekenen"
    v-tooltip.left="{ value: 'Nieuw puntelement tekenen' }"
  />
</template>

<style scoped lang="scss">
.active {
  background-color: var(--p-button-outlined-primary-color) !important;
  border-color: var(--p-button-outlined-primary-color) !important;
  color: #fff !important;
}
</style>

<script>
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Draw from "ol/interaction/Draw";
import { Snap, Modify } from "ol/interaction";
import { shiftKeyOnly } from "ol/events/condition";
import { Circle, Fill, Stroke, Style } from "ol/style";
import Button from "primevue/button";

export default {
  name: "CreateComponent",
  components: {
    Button,
  },
  data() {
    return {
      selected: "",
      draw: {
        modifyDraw: undefined,
        snap: undefined,
      },
      drawLayer: null,
      element: {
        cover: 0,
        diameter_id: 18,
        element_type: undefined,
        objective_id: 68,
        shape_id: 45,
        number_in_row: undefined,
        owners: [],
      },
    };
  },
  methods: {
    startDraw(type) {
      if ("Create" + type === this.$store.getters.activeTool) {
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        this.$store.commit("newInteraction", undefined);
        this.$store.commit("activeTool", undefined);
        this.$store.commit("setNewFeature", undefined);
        this.cancel();
        this.draw = { modifyDraw: undefined, snap: undefined };
        this.$store.commit("setActiveTab", "Legenda");
        return;
      }
      this.$store.commit("activeTool", "Create" + type);
      if (this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOff");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("newInteraction", undefined);
      this.draw = { modifyDraw: undefined, snap: undefined };
      this.$store.commit(
        "newInteraction",
        new Draw({
          source: this.drawLayer.getSource(),
          type,
          freehandCondition: shiftKeyOnly,
        })
      );
      this.$store.getters.interaction.on("drawstart", (event) => {
        this.drawLayer.getSource().clear();
        this.$store.commit("setNewFeature", undefined);
      });
      this.$store.getters.interaction.on("drawend", (event) => {
        this.$store.commit("setNewFeature", event.feature);
      });
      this.$store.getters.getMap.addInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("setActiveTab", "Element maken");
    },
    cancel() {
      this.drawLayer.getSource().clear();
      this.$store.commit("setActiveTab", "Legenda");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("newInteraction", undefined);
      this.$store.commit("activeTool", undefined);
      if (!this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOn");
    },
  },
  mounted() {
    if (!this.$store.getters.elementsData) {
      console.error("elementsData is not provided.");
      return;
    }
    this.drawLayer = new VectorLayer({
      source: new VectorSource({ wrapX: false }),
    });
    if (this.$store.getters.newFeature) {
      try {
        this.drawLayer.getSource().addFeature(this.$store.getters.newFeature);
      } catch (error) {
        console.error("Feature is already added", error);
      }
    }
    this.drawLayer.setStyle(
      new Style({
        stroke: new Stroke({
          color: "rgb(0, 0, 0)",
          width: 2,
        }),
        image: new Circle({
          fill: new Fill({
            color: "rgb(0, 0, 0)",
          }),
          stroke: new Stroke({
            color: "rgb(0, 0, 0)",
            width: 1.25,
          }),
          radius: 5,
        }),
      })
    );
    this.draw.modifyDraw = new Modify({
      source: this.drawLayer.getSource(),
    });
    this.$store.getters.getMap.addInteraction(this.draw.modifyDraw);
    this.draw.snap = new Snap({
      source: this.drawLayer.getSource(),
    });
    this.$store.getters.getMap.addInteraction(this.draw.snap);
    this.$store.getters.getMap.addLayer(this.drawLayer);
  },
};
</script>
