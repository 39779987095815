<template>
  <div class="container-fluid">
    <!-- Dashboard Row: Pie Chart and Info Cards -->
    <div class="row align-items-start">
      <!-- Left Column: Pie Chart -->
      <div class="col-md-9 mb-4 mb-md-0">
        <Card>
          <template #title>Onderhoudsstatus Grafiek</template>
          <template #content>
            <Chart type="pie" :data="pieChartData" :options="pieChartOptions" />
          </template>
        </Card>
      </div>

      <!-- Right Column: Info Cards -->
      <div class="col-md-3 d-flex flex-column">
        <Card class="mb-4">
          <template #title>Aantal elementen</template>
          <template #content>
            {{ info.element_count }}
          </template>
        </Card>
        <Card>
          <template #title>Uitgevoerd onderhoud</template>
          <template #content>
            {{ info.executed_maintenance }}
          </template>
        </Card>
      </div>
    </div>

    <!-- Data Table Row -->
    <div class="row mt-4">
      <div class="col">
        <Card>
          <template #title>Onderhoud met Deadlines Deze Maand</template>
          <template #content>
            <DataTable
              :value="maintenanceDeadlines"
              responsiveLayout="scroll"
              :rowClass="getRowClass"
            >
              <Column field="id" header="ID" />
              <Column field="element_id" header="Element ID" />
              <Column field="period_from" header="Startdatum">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.period_from) }}
                </template>
              </Column>
              <Column field="period_to" header="Einddatum">
                <template #body="slotProps">
                  <span :class="getDueDateClass(slotProps.data.period_to)">
                    {{ formatDate(slotProps.data.period_to) }}
                  </span>
                </template>
              </Column>
              <Column field="status" header="Status" />
            </DataTable>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import Chart from "primevue/chart";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

// Reactive state
const info = ref({
  element_count: 0,
  executed_maintenance: 0,
});

const pieChartData = ref({
  labels: [],
  datasets: [
    {
      data: [],
      // Optional: add colors for each segment (you may customize as needed)
      backgroundColor: [],
    },
  ],
});
const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

const maintenanceDeadlines = ref([]);

// Helper function to generate a random pastel color
const randomColor = () => {
  const r = Math.floor(Math.random() * 127 + 127);
  const g = Math.floor(Math.random() * 127 + 127);
  const b = Math.floor(Math.random() * 127 + 127);
  return `rgb(${r}, ${g}, ${b})`;
};

// Fetch maintenance statuses and update info and pie chart data
const getMaintenanceStatuses = async () => {
  try {
    const { data } = await axios.get(
      "/beheerapi/dashboards/maintenanceStatuses"
    );

    // Calculate totals from the fetched data
    info.value.element_count = data.reduce((sum, item) => sum + item.count, 0);
    info.value.executed_maintenance = data.reduce(
      (sum, item) => sum + (item.count || 0),
      0
    );

    // Update the pie chart data with labels, counts, and background colors
    pieChartData.value = {
      labels: data.map((item) => item.status),
      datasets: [
        {
          data: data.map((item) => item.count),
          backgroundColor: data.map(() => randomColor()),
        },
      ],
    };
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
  }
};

// Fetch maintenance deadlines for the current month
const getEndDateThisMonthData = async () => {
  try {
    const { data } = await axios.get("/beheerapi/dashboards/endDateThisMonth");
    maintenanceDeadlines.value = data;
  } catch (error) {
    console.error("Error fetching maintenance deadlines:", error);
  }
};

// Format dates using Dutch locale
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString("nl-NL", options);
};

// Return CSS classes for due dates if overdue
const getDueDateClass = (dueDate) => {
  const now = new Date();
  const deadline = new Date(dueDate);
  return deadline < now ? "text-danger font-weight-bold" : "";
};

// Return a row class if the row's end date is overdue
const getRowClass = (data) => {
  const now = new Date();
  const deadline = new Date(data.period_to);
  return deadline < now ? "row-danger" : "";
};

onMounted(() => {
  getMaintenanceStatuses();
  getEndDateThisMonthData();
});
</script>

<style scoped>
.text-danger {
  color: red;
}
.font-weight-bold {
  font-weight: bold;
}
.row-danger {
  background-color: rgba(
    255,
    0,
    0,
    0.1
  ); /* Light red background for overdue rows */
}
</style>
