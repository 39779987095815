<template>
  <div class="notes-content">
    <!-- Note Listing -->
    <div>
      <span v-if="!notesList.length" class="text-body-secondary"
        >Nog geen notities</span
      >
      <ul class="list-group">
        <li
          v-for="note in notesList"
          :key="note.id"
          class="list-group-item border-0 bg-light rounded mb-2"
        >
          <div class="fw-bold mb-1">
            <template v-if="note.user">
              {{ note.user.first_name + " " + note.user.last_name }}
            </template>
            <template v-else> Verwijderde gebruiker </template>
          </div>
          <div class="text-body-secondary small mb-1">
            <!-- Display created date -->
            <small>Gemaakt: {{ formatDate(note.created_at) }}</small>
          </div>
          <div class="text-body-secondary small mb-1" v-if="note.updated_at">
            <!-- Display updated date if available -->
            <small>Bijgewerkt: {{ formatDate(note.updated_at) }}</small>
          </div>
          <div class="text-dark">
            <!-- Show text normally unless editing this note -->
            <div v-if="editingNoteId !== note.id">
              {{ note.note }}
            </div>
            <!-- When editing, show an input -->
            <div v-else>
              <InputText
                type="text"
                v-model="editingNoteText"
                class="form-control"
              />
            </div>
          </div>
          <!-- Action Buttons aligned to right -->
          <div class="mt-2 text-end">
            <template v-if="editingNoteId !== note.id">
              <Button
                icon="pi pi-pencil"
                class="btn btn-outline-primary btn-sm me-2"
                @click="startEditing(note)"
              />
              <Button
                icon="pi pi-trash"
                class="btn btn-outline-danger btn-sm"
                :style="{ color: 'red' }"
                @click="confirmNoteDelete(note)"
              />
            </template>
            <template v-else>
              <Button
                label="Opslaan"
                class="btn btn-success btn-sm me-2"
                @click="updateNote(note)"
              />
              <Button
                label="Annuleren"
                class="btn btn-secondary btn-sm"
                @click="cancelEditing"
              />
            </template>
          </div>
        </li>
      </ul>
    </div>

    <!-- Note Adding Controls (always visible) -->
    <div class="mt-3">
      <InputText
        type="text"
        v-model="inputCreateNoteText"
        placeholder="Voeg een notitie toe..."
        class="form-control mb-2"
      />
      <Button label="Opslaan" class="btn btn-success" @click="addNote" />
    </div>

    <!-- Confirmation Dialog for Deletion -->
    <Dialog
      v-model:visible="deleteDialogVisible"
      :draggable="false"
      header="Bevestiging"
      modal
      :closable="false"
      style="width: 30vw"
    >
      <div>
        <p>Weet je zeker dat je de volgende notitie wilt verwijderen?</p>
        <div v-if="noteToDelete" class="card p-3 mb-3 border">
          <p class="mb-1">
            <strong>
              {{
                noteToDelete.user
                  ? noteToDelete.user.first_name +
                    " " +
                    noteToDelete.user.last_name
                  : "Verwijderde gebruiker"
              }}
            </strong>
          </p>
          <p class="mb-1">{{ noteToDelete.note }}</p>
          <p class="text-body-secondary mb-0">
            <small>Gemaakt: {{ formatDate(noteToDelete.created_at) }}</small>
          </p>
          <p class="text-body-secondary mb-0" v-if="noteToDelete.updated_at">
            <small>Bijgewerkt: {{ formatDate(noteToDelete.updated_at) }}</small>
          </p>
        </div>
      </div>
      <div class="text-end">
        <Button
          label="Annuleren"
          class="btn btn-secondary me-2"
          @click="cancelDelete"
        />
        <Button
          label="Verwijderen"
          class="btn btn-danger"
          @click="confirmDelete"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  name: "NotesContent",
  components: {
    InputText,
    Button,
    Dialog,
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    // Always required element ID.
    elementId: {
      type: Number,
      required: true,
    },
    // Optional maintenance ID.
    maintenanceId: {
      type: Number,
      default: null,
    },
    permissionForAdd: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputCreateNoteText: "",
      notesList: [...this.notes],
      editingNoteId: null,
      editingNoteText: "",
      deleteDialogVisible: false,
      noteToDelete: null,
    };
  },
  methods: {
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date).toLocaleDateString("nl-NL", options);
    },
    async addNote() {
      if (!this.inputCreateNoteText.trim()) return;
      const payload = {
        note: this.inputCreateNoteText,
        element_id: this.elementId,
      };
      if (this.maintenanceId !== null) {
        payload.maintenance_id = this.maintenanceId;
      }
      try {
        const response = await axios.post("/note/create", payload);
        this.notesList.unshift(response.data);
        this.inputCreateNoteText = "";
        this.$emit("note-added", response.data);
      } catch (error) {
        console.error("Error adding note:", error);
      }
    },
    startEditing(note) {
      this.editingNoteId = note.id;
      this.editingNoteText = note.note;
    },
    cancelEditing() {
      this.editingNoteId = null;
      this.editingNoteText = "";
    },
    async updateNote(note) {
      if (!this.editingNoteText.trim()) return;
      try {
        await axios.put("/note/update", {
          note_id: note.id,
          note_content: this.editingNoteText,
        });
        note.note = this.editingNoteText;
        // Set updated_at to the current date.
        note.updated_at = new Date();
        this.editingNoteId = null;
        this.editingNoteText = "";
        this.$emit("note-updated", note);
      } catch (error) {
        console.error("Error updating note:", error);
      }
    },
    confirmNoteDelete(note) {
      this.noteToDelete = note;
      this.deleteDialogVisible = true;
    },
    async confirmDelete() {
      if (!this.noteToDelete) return;
      try {
        await axios.post("/note/delete", { note_id: this.noteToDelete.id });
        const index = this.notesList.findIndex(
          (n) => n.id === this.noteToDelete.id
        );
        if (index !== -1) {
          this.notesList.splice(index, 1);
        }
        this.$emit("note-deleted", this.noteToDelete);
      } catch (error) {
        console.error("Error deleting note:", error);
      } finally {
        this.deleteDialogVisible = false;
        this.noteToDelete = null;
      }
    },
    cancelDelete() {
      this.deleteDialogVisible = false;
      this.noteToDelete = null;
    },
  },
  watch: {
    notes(newNotes) {
      this.notesList = [...newNotes];
    },
  },
};
</script>

<style scoped>
.list-group {
  list-style: none;
  padding: 0;
}
.list-group-item {
  margin-bottom: 0.5em;
}
</style>
