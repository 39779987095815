<template>
  <!-- Main container for the map and UI -->
  <div
    class="map-wrapper"
    style="position: relative; height: 100%; width: 100%"
  >
    <Toast position="top-center" />
    <!-- Map container -->
    <div ref="map-root" id="map-root" style="height: 100%; width: 100%"></div>

    <!-- Sidebar with tab menu and content -->
    <div
      class="tab-container"
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 100%;
        overflow: hidden;
        z-index: 10;
        background: white;
      "
    >
      <!-- TabMenu for navigation -->
      <TabMenu :model="tabItems" :activeIndex="activeItemIndex" />

      <!-- Tab content area -->
      <div
        class="tab-content"
        style="height: calc(100% - 40px); overflow: auto; padding: 1rem"
      >
        <template v-if="activeTab === 'Legenda'">
          <legend-component :layers="layers" :maps="maps" />
        </template>
        <template v-if="activeTab === 'Printen'">
          <print-component
            :organisation="organisation"
            :featureLayer="featureLayer"
          />
        </template>
        <template v-if="activeTab === 'Info'">
          <info-component :organisation="organisation" :user="user" />
        </template>
        <!-- Dynamic form is shown only when the active tab is "Create" -->
        <template v-if="activeTab === 'Element maken'">
          <div class="w-100">
            <dynamic-form
              :element="element"
              @submit="processRequest"
              @cancel="cancel"
            />
          </div>
        </template>
      </div>
    </div>

    <!-- Other controls such as background switch and tools -->
    <div class="position-absolute bottom-0 end-0 me-3 mb-3" style="z-index: 10">
      <Button
        @click="changeBackground"
        style="background-color: white; padding: 0"
      >
        <img
          :src="backgroundUrl"
          alt="Background switch"
          width="71"
          height="71"
        />
      </Button>
    </div>

    <tools-component v-if="mapLoaded" :organisation="organisation" />
  </div>
</template>

<script>
import { boundingExtent } from "ol/extent";
import { defaults as defaultControls } from "ol/control";
import View from "ol/View";
import Map from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import * as OlSource from "ol/source";
import * as OlLayers from "ol/layer";
import { Circle, Text, Fill, Stroke, Style } from "ol/style";
import { register } from "ol/proj/proj4";
import Projection from "ol/proj/Projection";
import proj4 from "proj4";
import "ol/ol.css";

import TabMenu from "primevue/tabmenu";
import { Button, Toast } from "primevue";
import LegendComponent from "./LegendComponent.vue";
import PrintComponent from "./PrintComponent.vue";
import InfoComponent from "./InfoComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import ToolsComponent from "./ToolsComponent.vue";
import DetailComponent from "./DetailComponent.vue";
import DynamicForm from "./DynamicForm.vue";
import Axios from "axios";

export default {
  name: "Map",
  components: {
    TabMenu,
    Button,
    LegendComponent,
    PrintComponent,
    InfoComponent,
    CreateComponent,
    ToolsComponent,
    DetailComponent,
    DynamicForm,
    Toast,
  },
  props: {
    organisation: Object,
    maps: Array,
    features: Object,
    user: Object,
    elements_data: Object,
  },
  data() {
    return {
      map: null,
      layers: [],
      mapLoaded: false,
      featureLayer: undefined,
      background: [],
      backgroundUrl: window.location.origin + "/img/legend/icons/satellite.png",
      // Data used by the dynamic form
      element: {
        cover: 0,
        diameter_id: 18,
        element_type: undefined,
        objective_id: 68,
        shape_id: 45,
        number_in_row: undefined,
        owners: [],
      },
      // Tab items with a command that commits the new active tab (using its label)
      tabItems: [
        {
          label: "Legenda",
          command: () => {
            this.activeItem = "Legenda";
            this.$store.commit("setActiveTab", "Legenda");
          },
        },
        {
          label: "Printen",
          command: () => {
            this.activeItem = "Printen";
            this.$store.commit("setActiveTab", "Printen");
          },
        },
        {
          label: "Info",
          command: () => {
            this.activeItem = "Info";
            this.$store.commit("setActiveTab", "Info");
          },
        },
      ],
    };
  },
  computed: {
    // Return the active tab label from the store for content display
    activeTab() {
      return this.$store.state.activeTab;
    },
    /* 
      The TabMenu expects the activeItem to be one of the objects from the tabItems array.
      This computed property maps the store's active tab (a string) to the corresponding object.
    */
    activeItemIndex: {
      get() {
        const activeIndex = this.tabItems.findIndex(
          (item) => item.label === this.activeTab
        );
        return activeIndex >= 0 ? activeIndex : 0;
      },
    },
    activeItem: {
      get() {
        return (
          this.tabItems.find((item) => item.label === this.activeTab) ||
          this.tabItems[0]
        );
      },
      set(value) {
        this.$store.commit("setActiveTab", value.label);
      },
    },
  },
  mounted() {
    // Commit elements_data to the store
    this.$store.commit("setElementsData", this.elements_data);

    // Check user roles and add the "Create" tab if allowed
    const roles = this.user.roles || [];
    roles.forEach((role) => {
      const r = JSON.parse(role.permissions);
      if (r.create_element || r.super || r.admin) {
        this.$store.commit("setCanCreate", true);
        // Add the "Create" tab only once
        if (!this.tabItems.find((item) => item.label === "Element maken")) {
          this.tabItems.push({
            label: "Element maken",
            command: () => {
              this.activeItem = "Element maken";
              this.$store.commit("setActiveTab", "Element maken");
            },
          });
        }
      }
    });

    // Set up the map projection and configuration
    const config = this.organisation.map_config;
    const projExtent = [-285401.92, 22598.08, 595401.92, 903401.92];
    const projection = new Projection({
      code: "EPSG:28992",
      units: "m",
      extent: projExtent,
    });
    proj4.defs(
      "EPSG:28992",
      "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs"
    );
    proj4.defs("urn:x-ogc:def:crs:EPSG:28992", proj4.defs("EPSG:28992"));
    proj4.defs(
      "http://www.opengis.net/gml/srs/epsg.xml#28992",
      proj4.defs("EPSG:28992")
    );
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    register(proj4);

    // Set up background layers
    this.background.push(
      new OlLayers.Tile({
        source: new OlSource.TileWMS({
          crossOrigin: "Anonymous",
          url: "https://mapproxy.gisarts.nl/service",
          params: {
            LAYERS: ["openstreetmap"],
            FORMAT: "image/png",
            TRANSPARENT: true,
            VERSION: "1.1.1",
            TILED: false,
          },
        }),
      })
    );
    this.background.push(
      new OlLayers.Image({
        source: new OlSource.ImageWMS({
          crossOrigin: "Anonymous",
          url: "https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0",
          params: {
            LAYERS: ["2021_orthoHR"],
            FORMAT: "image/png",
            TRANSPARENT: true,
            VERSION: "1.1.1",
            TILED: false,
          },
        }),
        visible: false,
      })
    );

    // If features are passed, create a vector layer for them
    if (this.features) {
      this.featureLayer = new OlLayers.Vector({
        zIndex: 1000,
        source: new OlSource.Vector({
          features: new GeoJSON().readFeatures(this.features),
        }),
      });
      this.featureLayer.setStyle((feature) => {
        return new Style({
          stroke: new Stroke({ color: "#af41f4", width: 2 }),
          text: new Text({
            text: feature.get("text"),
            textAlign: "center",
            offsetY: -20,
            scale: 1.5,
            overflow: true,
            stroke: new Stroke({ color: "#ffffff", width: 2 }),
          }),
          image: new Circle({
            radius: 7,
            fill: new Fill({ color: "#af41f4" }),
          }),
        });
      });
    }

    // Create the map and commit it to the store
    this.$store.commit(
      "setMap",
      new Map({
        target: this.$refs["map-root"],
        layers: this.background,
        controls: defaultControls(),
        view: new View({
          projection,
          center: [197800, 454050],
          minZoom: config.minZoom ? config.minZoom : 5,
          maxZoom: config.maxZoom ? config.maxZoom : 22,
        }),
      })
    );
    this.$store.getters.getMap.addControl(this.$store.getters.scaleline);
    this.$store.dispatch("toggleClickOn");

    // Add additional map layers
    this.maps.forEach((l) => {
      this.addLayer(this.$store.getters.getMap, l.options);
    });
    if (this.featureLayer) {
      this.$store.getters.getMap.addLayer(this.featureLayer);
      this.$store.getters.getMap
        .getView()
        .fit(this.featureLayer.getSource().getExtent(), {
          padding: [200, 200, 200, 200],
          maxZoom: 17,
        });
    } else {
      this.zoomToExtent(this.$store.getters.getMap, config.extent);
    }
    this.layers = this.$store.getters.getMap.getLayers().array_;
    this.mapLoaded = true;

    // If features are already present, switch to the "Info" tab.
    if (
      this.featureLayer &&
      this.featureLayer.getSource()?.getFeatures()?.length > 0
    ) {
      this.$store.commit("setActiveTab", "Info");
    }
  },
  methods: {
    zoomToExtent(map, extent) {
      if (Array.isArray(extent)) {
        extent = boundingExtent(extent);
      } else {
        const geojson = new GeoJSON();
        const feature = geojson.readFeature(extent);
        extent = feature.getGeometry().getExtent();
      }
      map.getView().fit(extent, map.getSize());
    },
    addLayer(map, l) {
      const layers = [];
      let featureTypes = [];
      l.source.layers.forEach((l) => {
        if (typeof l === "string") {
          layers.push(l);
        } else if (l.name !== undefined) {
          if (l.visible !== false) {
            layers.push(l.name);
          }
          if (l.protocol !== undefined) {
            this._featureService.protocol.push(l.name);
          }
          if (!map.featureTypes && l.featureType) {
            featureTypes.push(l.featureType);
          }
        }
      });
      if (l.source.layer_groups && l.source.layer_groups.length) {
        l.source.layer_groups.forEach((group) => {
          if (group.layerName) {
            if (group.visible) {
              layers.push(group.layerName);
            }
          } else {
            group.layers.forEach((l) => {
              if (typeof l === "string") {
                layers.push(l);
              } else if (l.name !== undefined) {
                if (l.visible !== false) {
                  layers.push(l.name);
                }
                if (l.protocol !== undefined) {
                  this._featureService.protocol.push(l.name);
                }
                if (!map.featureTypes && l.featureType) {
                  featureTypes.push(l.featureType);
                }
              }
            });
          }
        });
      }
      let layerSource;
      switch (l.type) {
        case "Image":
          layerSource = this.createImageSource(l, layers);
          break;
        case "Tile":
          layerSource = this.createTileSource(l, layers);
          break;
        case "Vector":
          layerSource = this.createVectorSource(l, layers);
          break;
        default:
          break;
      }
      if (l.source.marker && !this.clickService.zoomToClusterEnabled) {
        this.clickService.zoomToClusterEnabled = true;
      }
      if (l.source.attributions) {
        layerSource.setAttributions(l.source.attributions);
      }
      const newLayer = new OlLayers[l.type]({
        title: l.name,
        source: layerSource,
        visible: l.visible !== undefined ? l.visible : true,
        maxResolution:
          l.maxResolution !== undefined ? l.maxResolution : undefined,
        minResolution:
          l.minResolution !== undefined ? l.minResolution : undefined,
        opacity: l.opacity !== undefined ? l.opacity : 1,
        featureClick: l.featureClick !== undefined ? l.featureClick : undefined,
        collapsed: l.collapsed !== undefined ? l.collapsed : true,
        icon: l.icon !== undefined ? l.icon : false,
        order: l.order,
      });
      map.addLayer(newLayer);
    },
    processRequest(elementData) {
      const element = { ...elementData };

      element.organisation_id = this.organisation.id;
      element.created_by = this.user.id;
      element.element_type_id = element.element_type;
      if (this.user.owner_id) {
        element.owners = [this.user.owner_id];
      }

      if (this.organisation.id === 14) {
        element.organisation_id = 14;
        element.district_id = 25;
        element.created_by = 1;
        delete element.type;
        this.$store.getters.newFeature.unset("type");
      }

      let geom = this.$store.getters.newFeature.getGeometry();

      if (geom.getType() === "LineString") {
        let length = Math.round(geom.getLength());
        element.length = length;
      } else if (geom.getType() === "Polygon") {
        let area = Math.round(geom.getArea());
        element.area = area;
      }

      element.geom = new GeoJSON().writeFeatureObject(
        this.$store.getters.newFeature
      ).geometry;

      if (element.owners && element.owners.length > 0) {
        element.owner_ids = element.owners;
      }

      this.sendRequest(element).then((response) => {
        for (let layer of this.$store.getters.getMap.getLayers().array_) {
          if (typeof layer.getSource().clear === "function") {
            layer.getSource().clear();
          } else {
            layer.getSource().refresh();
          }
        }

        this.$store.commit("setActiveTab", "Legenda");
        this.$store.getters.getMap.removeInteraction(
          this.$store.getters.interaction
        );
        this.$store.commit("newInteraction", undefined);
        this.$store.commit("activeTool", undefined);
        if (!this.$store.getters.infoClickState)
          this.$store.dispatch("toggleClickOn");
      });
    },
    sendRequest(data) {
      return Axios({
        method: "post",
        url: "/elementen/store/general",
        data,
      });
    },
    cancel() {
      this.drawLayer.getSource().clear();
      this.$store.commit("setActiveTab", "Legenda");
      this.$store.getters.getMap.removeInteraction(
        this.$store.getters.interaction
      );
      this.$store.commit("newInteraction", undefined);
      this.$store.commit("activeTool", undefined);
      if (!this.$store.getters.infoClickState)
        this.$store.dispatch("toggleClickOn");
    },
    createImageSource(map, layers) {
      let params = {
        LAYERS: layers,
        FORMAT: map.source.params.format || "image/png",
        TRANSPARENT: map.source.params.transparent,
        VERSION: map.source.params.version || "1.1.1",
        TILED: false,
        map: map.source.params.map,
      };
      let layerSource;
      switch (map.source.type) {
        case "ImageWMS":
          layerSource = new OlSource.ImageWMS({
            url: map.source.url,
            params,
            crossOrigin: "Anonymous",
            attributions: map.source.attributions,
            serverType: map.source.serverType || "mapserver",
          });
          if (map.authorization) {
            layerSource.setImageLoadFunction(async function (image, url) {
              const headers = new Headers();
              headers.append("Authorization", map.authorization);
              const init = { headers, method: "GET" };
              const response = await fetch(url, init);
              const imageData = await response.blob();
              const imageElement = image.getImage();
              imageElement.src = window.URL.createObjectURL(imageData);
            });
          }
          break;
        case "ImageArcGISRest":
          layerSource = new OlSource.ImageArcGISRest({
            url: map.source.url,
            crossOrigin: "Anonymous",
            params,
          });
          break;
        default:
          layerSource = new OlSource[map.source.type]({
            url: map.source.url,
            params,
          });
          break;
      }
      return layerSource;
    },
    changeBackground() {
      this.background.forEach((layer) => {
        layer.setVisible(!layer.getVisible());
      });
      const osmBackground =
        window.location.origin + "/img/legend/icons/map.png";
      this.backgroundUrl =
        this.backgroundUrl === osmBackground
          ? window.location.origin + "/img/legend/icons/satellite.png"
          : osmBackground;
    },
  },
};
</script>
