<template>
  <div class="container-fluid my-3">
    <div class="row">
      <!-- Top Menu for Small Screens -->
      <div class="col-12 d-block d-md-none mb-3">
        <TieredMenu :model="items">
          <template #item="{ item, props, hasSubmenu }">
            <router-link
              v-if="item.route"
              v-slot="{ href, navigate }"
              :to="item.route"
              custom
            >
              <a
                v-ripple
                :href="href"
                v-bind="props.action"
                @click="navigate"
                class="d-flex align-items-center p-2"
              >
                <span :class="item.icon"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </router-link>
            <a
              v-else
              v-ripple
              :href="item.url"
              :target="item.target"
              v-bind="props.action"
              class="d-flex align-items-center p-2"
            >
              <span :class="item.icon"></span>
              <span class="ml-2">{{ item.label }}</span>
              <span v-if="hasSubmenu" class="pi pi-angle-right ml-auto"></span>
            </a>
          </template>
        </TieredMenu>
      </div>

      <!-- Sidebar for md+ Screens -->
      <div class="col-md-2 d-none d-md-block">
        <TieredMenu :model="items">
          <template #item="{ item, props, hasSubmenu }">
            <router-link
              v-if="item.route"
              v-slot="{ href, navigate }"
              :to="item.route"
              custom
            >
              <a
                v-ripple
                :href="href"
                v-bind="props.action"
                @click="navigate"
                class="d-flex align-items-center p-2"
              >
                <span :class="item.icon"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </router-link>
            <a
              v-else
              v-ripple
              :href="item.url"
              :target="item.target"
              v-bind="props.action"
              class="d-flex align-items-center p-2"
            >
              <span :class="item.icon"></span>
              <span class="ml-2">{{ item.label }}</span>
              <span v-if="hasSubmenu" class="pi pi-angle-right ml-auto"></span>
            </a>
          </template>
        </TieredMenu>
      </div>

      <!-- Main Content -->
      <div class="col-md-10 col-12">
        <router-view :organisation="organisation" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import TieredMenu from "primevue/tieredmenu";

// Define props
const props = defineProps({
  organisation: Object,
  user: Object,
});

// Set up router
const router = useRouter();

const items = ref([
  {
    label: "Overzicht",
    icon: "pi pi-home",
    route: "/beheer",
  },
  {
    label: "Gebruikers",
    icon: "pi pi-user",
    route: "/beheer/users",
  },
  {
    label: "Rollen",
    icon: "pi pi-lock",
    route: "/beheer/roles",
  },
  {
    label: "Locaties",
    icon: "pi pi-map-marker",
    url: "/locations",
  },
  {
    label: "Districten",
    icon: "pi pi-sitemap",
    url: "/districts",
  },
  {
    label: "Organizatie instellingen",
    icon: "pi pi-cog",
    items: [], // Placeholder: will be populated dynamically
  },
]);

// Reactive reference for settings types
const settingsTypes = ref([]);

// Fetch settings types from the API and update the menu
const fetchSettingsTypes = async () => {
  try {
    const response = await axios.get("/beheerapi/settings/types/list");
    settingsTypes.value = response.data;
    populateOrganizationSettings();
  } catch (error) {
    console.error("Error fetching settings types:", error);
  }
};

// Populate the "Organizatie instellingen" submenu
const populateOrganizationSettings = () => {
  const organizationSettings = settingsTypes.value.map((settingType) => ({
    label: settingType.displayName,
    icon: "pi pi-cog",
    route: `/beheer/settings/${settingType.tableName}`,
  }));

  const orgSettingsMenu = items.value.find(
    (item) => item.label === "Organizatie instellingen"
  );
  if (orgSettingsMenu) {
    orgSettingsMenu.items = organizationSettings;
  }
};

onMounted(() => {
  fetchSettingsTypes();
});
</script>

<style scoped>
/* Additional styling if needed */
</style>
