<template>
  <Card>
    <template #title>
      <h4>Geschiedenis Onderhoud</h4>
    </template>
    <template #content>
      <Accordion value="0" v-if="element.maintenance_archived.length > 0">
        <AccordionPanel
          v-for="(maint, i) in maintenanceList"
          :value="i"
          :key="maint.id"
        >
          <AccordionHeader :class="maint.status?.maintenance_status">
            {{ maint.maintenancetype.maintenance_type_nl }},
            {{ maint.status.maintenance_status_nl }}
          </AccordionHeader>
          <AccordionContent>
            <div class="row">
              <div class="col-md-1">
                <div class="status-box"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <b>Periode vanaf</b>
                <p>{{ formatDate(maint.period_from) }}</p>
              </div>
              <div class="col-md-6">
                <b>Periode tot</b>
                <p>{{ formatDate(maint.period_to) }}</p>
              </div>
              <div class="col-md-6">
                <b>Rekenwaarde</b>
                <p>{{ maint.calculation_value }}</p>
              </div>
              <div class="col-md-6">
                <b>Cyclus</b>
                <p>{{ maint.cycle.cycle_nl }}</p>
              </div>
              <div class="col-md-6">
                <b>Gereedschap</b>
                <p>{{ maint.tool.map((item) => item.tool_nl).join(", ") }}</p>
              </div>
              <div class="col-md-6" v-if="maint.phasing">
                <b>Fasering</b>
                <p>{{ maint.phasing.phasing }}</p>
              </div>
              <div class="col-md-6" v-if="maint.waste">
                <b>Vrijgekomen materiaal</b>
                <p>{{ maint.waste.map((item) => item.waste).join(", ") }}</p>
              </div>
              <div class="col-md-6">
                <b>Uitvoerder</b>
                <p v-if="maint.executed_by">
                  {{ maint.executed_by.executed_by }}
                </p>
              </div>
              <div class="col-md-6" v-if="maint.processing">
                <b>Verwerking</b>
                <p>
                  {{
                    maint.processing.map((item) => item.processing).join(", ")
                  }}
                </p>
              </div>
            </div>

            <hr />

            <div
              v-if="hasPermissions(['user', 'maintenance_profits', 'admin'])"
            >
              <div class="row">
                <div class="col-md-6">
                  <b>Verwachte opbrengst</b>
                  <p>
                    {{ maint.profit ? maint.profit.expected_profit : "" }}
                    {{
                      maint.profit && maint.profit.expected_profit_unit
                        ? maint.profit.expected_profit_unit.unit
                        : ""
                    }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Opbrengst</b>
                  <p>
                    {{ maint.profit ? maint.profit.profit : "" }}
                    {{
                      maint.profit && maint.profit.expected_profit_unit
                        ? maint.profit.expected_profit_unit.unit
                        : ""
                    }}
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b>Datum beschikbaar</b>
                  <p>{{ maint.profit ? maint.profit.available : "" }}</p>
                </div>
                <div class="col-md-6">
                  <b>Datum opgehaald</b>
                  <p>{{ maint.profit ? maint.profit.pickup : "" }}</p>
                </div>
              </div>
            </div>

            <div
              v-if="hasPermissions(['admin', 'user', 'add_maintenance_notes'])"
            ></div>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <div v-else>Geen onderhoudsgegevens beschikbaar.</div>
    </template>
  </Card>
</template>

<script>
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import DatePicker from "primevue/datepicker";
import { inject } from "vue";
import axios from "axios";

import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

import Select from "primevue/select";
import { Card } from "primevue";

export default {
  name: "Maintenance",
  components: {
    Dropdown,
    AutoComplete,
    DatePicker,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    InputNumber,
    InputText,
    Select,
    Card,
  },
  props: {
    element: Object,
    organisation: Object,
    user: Object,
    element_data: Object,
  },
  data() {
    return {
      maintenanceList: this.element.maintenance_archived.map((maint) => ({
        ...maint,
        editing: false,
      })),
      suggestions: {},
    };
  },
  mounted() {
    console.log(this.element);
  },
  methods: {
    hasPermissions(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    canEditStatus(id) {
      return true;
    },

    editMaintenance(maint) {
      maint.editing = !maint.editing;
    },
    async saveMaintenance(maint) {
      console.log("Saving maintenance", maint);

      maint.editing = !maint.editing;

      const body = {
        element_id: this.element.id,
        maintenance_id: maint.id,
        maint,
        // status: maint.status.maintenance_status,
        // period_from: maint.period_from,
        // period_to: maint.period_to,
        // // note: undefined,
        // phasing_text: undefined,
        // tool_id: undefined,
        // waste_id: undefined,
        // processing_id: undefined,
        // unit_id: undefined,
        // profit: undefined,
        // phasing_id: undefined,
        // executed_by_id: undefined,
        // calculation_value: undefined,
        // cost_id: 1,
        // min: 0,
        // maintenance_status_id: undefined,
        // status_updated_at: '',
      };

      console.log(body);
      await axios
        .post("/elementen/store/maintenance", maint)
        .then((response) => {
          console.log(response);
        });
    },
    async deleteMaintenance(index, id) {
      this.maintenanceList.splice(index, 1);

      await axios
        .post("/elementen/remove/maintenance", { id })
        .then((response) => {
          console.log(response);
        });
    },
    async updateStatus(event, maint) {
      console.log(event);
      const body = {
        element_id: this.element.id,
        maintenance_id: maint.id,
        status: event.value.maintenance_status,
      };
      await axios
        .post("/elementen/store/maintenance_status", body)
        .then((response) => {
          console.log(response);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    elementHasOwner() {
      this.element.owners.forEach((owner) => {
        if (owner.id === this.user.owner_id) {
          return true;
        }
      });

      return false;
    },
    canEditElement() {
      // if () {
      // }
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();

      // Filter de array
      const filtered = Object.values(this.element_data[fieldName]).filter(
        (item) => {
          console.log(item);
          if (typeof item === "string") {
            return item.toLowerCase().includes(query.toLowerCase());
          } else if (
            typeof item === "object" &&
            item !== null &&
            "tool_nl" in item
          ) {
            return item.tool_nl.toLowerCase().includes(query.toLowerCase());
          } else if (
            typeof item === "object" &&
            item !== null &&
            "unit" in item
          ) {
            return item.unit.toLowerCase().includes(query.toLowerCase());
          }

          return false;
        }
      );

      // Zet de gefilterde resultaten in de juiste veld-sleutel binnen `suggestions`
      this.suggestions[fieldName] = filtered;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../scss/maintenace_statuses.scss";
/* Add scoped styles here */
</style>
