<template>
  <div>
    <h4>Legenda</h4>
    <ul class="legend" v-if="layers.length">
      <template v-for="layer in layers" :key="layer.name">
        <li
          class="category"
          v-if="maps.some((e) => e.options.name === layer.get('title'))"
        >
          <!-- Layer Collapse Toggle -->
          <div class="state" @click="toggleLayerCollapse(layer)">
            <svg
              v-if="layer.get('collapsed')"
              class="bi bi-chevron-down"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
              />
            </svg>
            <svg
              v-else
              class="bi bi-chevron-down"
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>

          <!-- Layer Checkbox -->
          <div class="checkbox">
            <div class="form-check">
              <input
                class="ml-1"
                type="checkbox"
                :id="layer.get('title')"
                :checked="layer.getVisible()"
                @click="toggleLayer(layer)"
              />
              <label :for="layer.get('title')">
                <img v-if="layer.get('icon')" :src="layer.get('icon')" />
                {{ layer.get("title") }}
              </label>
            </div>

            <!-- Sublayers -->
            <div v-if="!layer.get('collapsed')">
              <ul
                v-if="
                  !maps.find((e) => e.options.name === layer.get('title'))
                    .options.hideInLayerSwitcher
                "
              >
                <li
                  class="layer"
                  v-for="paramLayer in maps.find(
                    (e) => e.options.name === layer.get('title')
                  ).options.source.layers"
                  :key="paramLayer.name"
                >
                  <div class="form-check">
                    <input
                      v-if="paramLayer.name"
                      type="checkbox"
                      :id="paramLayer.label"
                      :checked="getParamLayerVisibility(layer, paramLayer)"
                      @click="updateLayer(layer, paramLayer.name)"
                    />
                    <label :for="paramLayer.label">
                      <img v-if="paramLayer.icon" :src="paramLayer.icon" />
                      {{ paramLayer.label }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<style>
div.category {
  float: left;
}
div.state {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
}

ul.legend {
  padding-left: 0px;
}

div.checkbox {
  margin: 2px 0 2px 30px;
}

div.form-check {
  padding-left: 0px;
}
div.form-check label {
  padding-left: 0px;
}

div.form-check input[type="checkbox"] {
  margin-right: 4px;
  margin-left: 0;
}
.metadata {
  float: right;
  height: 24px;
}
li.category {
  width: 100%;
  min-height: 25px;
  display: block;
  list-style-type: none;
}
li.layer {
  margin-left: 20px;
  list-style-type: none;
}
</style>

<script>
export default {
  name: "legend-component",
  props: {
    maps: Array,
    layers: Array,
  },
  methods: {
    toggleLayerCollapse(layer) {
      const isCollapsed = !!layer.get("collapsed");
      layer.set("collapsed", !isCollapsed);
    },

    toggleLayer(layer) {
      const layerVisible = layer.getVisible();
      layer.setVisible(!layerVisible);

      const map = this.maps.find((m) => m.name === layer.get("title"));
      const layers = map?.options?.source?.layers ?? [];

      if (layers.length > 0) {
        const paramLayerNames = layers.map((layer) => layer.name);

        const params = layer.getSource().getParams();
        params.LAYERS = layerVisible ? [] : paramLayerNames;
        layer.getSource().updateParams(params);

        layers.forEach((paramLayer) => {
          paramLayer.visible = !layerVisible;
        });

        layer.getSource().dispatchEvent("change");
      }
    },

    updateLayer(layer, paramLayerName) {
      const source = layer.getSource();
      const params = source.getParams();

      const index = params.LAYERS.indexOf(paramLayerName);
      if (index !== -1) {
        params.LAYERS.splice(index, 1);
      } else {
        params.LAYERS.push(paramLayerName);
      }

      source.updateParams(params);

      const isAnySubLayerVisible = params.LAYERS.length > 0;

      layer.setVisible(isAnySubLayerVisible);

      source.dispatchEvent("change");
    },
    getParamLayerVisibility(layer, paramLayer) {
      if (!layer.getVisible()) return false;
      const params = layer.getSource().getParams();
      return params.LAYERS.includes(paramLayer.name);
    },
  },
};
</script>
