<template>
  <Card class="col-md-10 mx-auto">
    <template #title>
      <h2>
        {{ isEditMode ? "Gebruiker Bewerken" : "Nieuwe Gebruiker Toevoegen" }}
      </h2>
    </template>

    <template #content>
      <form @submit.prevent="saveUser">
        <!-- Username -->
        <div class="form-group mb-3">
          <label for="username">Gebruikersnaam</label>
          <InputText
            id="username"
            v-model="form.username"
            class="form-control"
            required
          />
        </div>
        <!-- First Name -->
        <div class="form-group mb-3">
          <label for="first_name">Naam</label>
          <InputText
            id="first_name"
            v-model="form.first_name"
            class="form-control"
            required
          />
        </div>
        <!-- Last Name -->
        <div class="form-group mb-3">
          <label for="last_name">Achternaam</label>
          <InputText
            id="last_name"
            v-model="form.last_name"
            class="form-control"
            required
          />
        </div>
        <!-- Email -->
        <div class="form-group mb-3">
          <label for="email">E-mail</label>
          <InputText
            id="email"
            v-model="form.email"
            class="form-control"
            required
          />
        </div>
        <!-- Company AutoComplete -->
        <div class="mb-3">
          <label for="company">Bedrijf</label>
          <AutoComplete
            id="company"
            v-model="form.company"
            optionLabel="executed_by"
            dropdown
            :suggestions="companySuggestions"
            @complete="searchCompanies"
            class="form-control"
            placeholder="Selecteer een bedrijf"
          />
        </div>
        <!-- Roles AutoComplete -->
        <div class="mb-3">
          <label for="roles">Rollen</label>
          <AutoComplete
            id="roles"
            v-model="form.roles"
            optionLabel="name"
            multiple
            dropdown
            :suggestions="roleSuggestions"
            @complete="searchRoles"
            class="form-control"
          />
        </div>
        <!-- Owner AutoComplete -->
        <div class="mb-3">
          <label for="owner">Eigenaar (optioneel)</label>
          <AutoComplete
            id="owner"
            v-model="form.owner"
            optionLabel="owner"
            dropdown
            :suggestions="ownerSuggestions"
            @complete="searchOwners"
            class="form-control"
            placeholder="Kies een eigenaar (optioneel)"
          />
        </div>
      </form>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between mt-2">
        <Button label="Annuleren" class="p-button-secondary" @click="cancel" />
        <Button
          type="button"
          :label="isEditMode ? 'Bijwerken' : 'Toevoegen'"
          class="p-button-success"
          @click="saveUser"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Card from "primevue/card";
import axios from "axios";

export default {
  name: "UserFormComponent",
  components: {
    InputText,
    Button,
    AutoComplete,
    Card,
  },
  props: {
    organisation: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    // Available roles from the organisation prop.
    const roles = props.organisation?.roles || [];
    // Map companies so that each has a valid "executed_by" property.
    // The first element is the default: "Geen bedrijf".
    const companies = [
      { id: null, executed_by: "Geen bedrijf" },
      ...(props.organisation?.companies || []),
    ];

    const roleSuggestions = ref([]);
    const companySuggestions = ref([]);
    const ownerSuggestions = ref([]);
    // Store all owners.
    const allOwners = ref([]);

    // The form model. Note that for editing, we expect company, roles, and owner as objects.
    const form = ref({
      id: null,
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      roles: [],
      company_id: null,
      owner: null,
    });

    const isEditMode = ref(false);

    // Load all owners first, then fetch the user if in edit mode.
    onMounted(async () => {
      await fetchAllOwners();
      if (route.params.id) {
        isEditMode.value = true;
        await fetchUser(route.params.id);
      }
    });

    // Load all owners.
    const fetchAllOwners = async () => {
      try {
        const response = await axios.get("/beheerapi/owners/list");
        allOwners.value = response.data;
      } catch (error) {
        console.error("Fout bij ophalen van alle eigenaren:", error);
      }
    };

    // Fetch user data in edit mode.
    const fetchUser = async (id) => {
      try {
        const response = await axios.get(`/beheerapi/users/${id}`);
        const data = response.data;
        form.value = {
          ...form.value,
          ...data,
          // Use the API’s returned roles array directly.
          roles: data.roles || [],
          // Find the company object using company_id (if non-zero),
          // otherwise use the default company ("Geen bedrijf").
          company:
            data.company_id && data.company_id !== 0
              ? companies.find((company) => company.id === data.company_id)
              : companies[0],
          // Lookup the owner from the loaded owners list.
          owner:
            data.owner_id && allOwners.value.length
              ? allOwners.value.find((owner) => owner.id === data.owner_id) ||
                null
              : null,
        };
      } catch (error) {
        console.error("Fout bij ophalen van gebruiker:", error);
      }
    };

    // Role suggestions.
    const searchRoles = (event) => {
      const query = (event.query || "").toLowerCase();
      roleSuggestions.value = roles.filter((role) =>
        role.name.toLowerCase().includes(query)
      );
    };

    // Company suggestions.
    const searchCompanies = (event) => {
      const query = (event.query || "").toLowerCase();
      companySuggestions.value = companies.filter((company) =>
        company.executed_by.toLowerCase().includes(query)
      );
    };

    // Owner suggestions.
    const searchOwners = (event) => {
      const query = (event.query || "").toLowerCase();
      ownerSuggestions.value = allOwners.value.filter(
        (owner) =>
          owner && owner.owner && owner.owner.toLowerCase().includes(query)
      );
    };

    // Save the user.
    const saveUser = async () => {
      try {
        const payload = { ...form.value };

        // Convert roles to role_ids.
        if (Array.isArray(payload.roles) && payload.roles.length > 0) {
          payload.role_ids = payload.roles.map((role) => role.id);
        } else {
          payload.role_ids = [];
        }
        delete payload.roles;

        // Set owner_id.
        if (payload.owner && payload.owner.id) {
          payload.owner_id = payload.owner.id;
        } else {
          payload.owner_id = null;
        }
        delete payload.owner;

        // Set company_id from the selected company.
        if (payload.company && payload.company.id !== undefined) {
          payload.company_id = payload.company.id;
        } else {
          payload.company_id = null;
        }
        delete payload.company;

        if (isEditMode.value) {
          await axios.put(`/beheerapi/users/${payload.id}`, payload);
        } else {
          await axios.post("/beheerapi/users", payload);
        }
        router.push("/beheer/users");
      } catch (error) {
        console.error("Fout bij opslaan van gebruiker:", error);
      }
    };

    const cancel = () => {
      router.push("/beheer/users");
    };

    return {
      form,
      isEditMode,
      saveUser,
      cancel,
      searchRoles,
      roleSuggestions,
      searchCompanies,
      companySuggestions,
      searchOwners,
      ownerSuggestions,
      companies,
    };
  },
};
</script>
