<template>
  <div class="col-md-10">
    <h3>Rollen</h3>
    <Button
      label="Toevoegen Rol"
      icon="pi pi-plus"
      class="p-mb-3"
      @click="addRole"
    />
    <DataTable
      :value="roles"
      paginator
      sortMode="multiple"
      :lazy="true"
      :rows="pagination.per_page"
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :totalRecords="pagination.total"
      @page="updatePagination"
      @sort="updatePagination"
      stripedRows
    >
      <Column field="name" header="Name" style="width: 25%"></Column>
      <Column header="Bewerken" :sortable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            severity="success"
            variant="text"
            rounded
            aria-label="Bewerken"
            @click="editRole(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Button from "primevue/button";

export default {
  name: "RolesOverviewComponent",
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Button,
  },
  props: {
    organisation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      roles: [],
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
        sort: null,
      },
      search: "",
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      try {
        const response = await axios.get("/beheerapi/roles/list", {
          params: {
            page: this.pagination.current_page,
            per_page: this.pagination.per_page,
            search: this.search,
            sort: JSON.stringify(this.pagination.sort),
          },
        });

        const rolesData = response.data.roles;
        this.roles = rolesData.data;
        this.pagination.current_page = rolesData.current_page;
        this.pagination.last_page = rolesData.last_page;
        this.pagination.per_page = +rolesData.per_page;
        this.pagination.total = rolesData.total;
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    updatePagination(event) {
      // Adjust page index if needed (assumes backend pages start at 1)
      this.pagination.current_page = event.page + 1;
      this.pagination.per_page = event.rows;

      if (event.sortField) {
        this.pagination.sort = {
          field: event.sortField,
          order: event.sortOrder,
        };
      }
      this.getRoles();
    },
    editRole(roleId) {
      this.$router.push(`/beheer/roles/${roleId}`);
    },
    addRole() {
      this.$router.push("/beheer/roles/new");
    },
  },
};
</script>

<style scoped>
/* Add component-specific styles here */
</style>
