<template>
  <Card class="mt-4">
    <template #title>
      <h4 class="mb-3">Nieuwe eigenaar</h4>
    </template>

    <template #content>
      <form @submit.prevent="submitForm">
        <Message
          v-if="success"
          severity="success"
          :closable="false"
          class="mb-3"
        >
          {{ success }}
        </Message>

        <div class="mb-3">
          <label for="owner" class="form-label">Eigenaar</label>
          <InputText
            v-model="form.owner"
            id="owner"
            placeholder="Eigenaar"
            class="form-control"
          />
          <small v-if="errors.owner" class="text-danger">
            {{ errors.owner }}
          </small>
        </div>

        <div class="mb-3">
          <label for="street" class="form-label">Straat</label>
          <InputText
            v-model="form.street"
            id="street"
            placeholder="Straat"
            class="form-control"
          />
          <small v-if="errors.street" class="text-danger">
            {{ errors.street }}
          </small>
        </div>

        <div class="mb-3">
          <label for="postal" class="form-label">Postcode</label>
          <InputText
            v-model="form.postal"
            id="postal"
            placeholder="Postcode"
            class="form-control"
          />
          <small v-if="errors.postal" class="text-danger">
            {{ errors.postal }}
          </small>
        </div>

        <div class="mb-3">
          <label for="city" class="form-label">Woonplaats</label>
          <InputText
            v-model="form.city"
            id="city"
            placeholder="Woonplaats"
            class="form-control"
          />
          <small v-if="errors.city" class="text-danger">
            {{ errors.city }}
          </small>
        </div>

        <div class="mb-3">
          <label for="phonenumber" class="form-label">Telefoonnummer</label>
          <InputText
            v-model="form.phonenumber"
            id="phonenumber"
            placeholder="Telefoonnummer"
            class="form-control"
          />
          <small v-if="errors.phonenumber" class="text-danger">
            {{ errors.phonenumber }}
          </small>
        </div>

        <!-- Submit Button -->
        <Button
          type="submit"
          label="Opslaan"
          icon="pi pi-check"
          class="btn btn-primary mt-3"
        />
      </form>
    </template>
  </Card>
</template>


<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";
import axios from "axios";
import { Card } from "primevue";

export default {
  components: {
    InputText,
    Button,
    Message,
    Card,
  },
  data() {
    return {
      form: {
        owner: "",
        street: "",
        postal: "",
        city: "",
        phonenumber: "",
      },
      success: null,
      errors: {},
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post("/owners/new_owner", this.form);
        this.success = response.data.message || "Eigenaar succesvol aangemaakt";
        this.errors = {};
        this.resetForm();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          console.error(error);
        }
      }
    },
    resetForm() {
      this.form = {
        owner: "",
        street: "",
        postal: "",
        city: "",
        phonenumber: "",
      };
    },
  },
};
</script>

<style scoped>
.p-field {
  margin-bottom: 1rem;
}

.p-error {
  font-size: 0.875rem;
}
</style>
