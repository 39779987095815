<template>
  <Card class="col-md-10 mx-auto">
    <template #title>
      <h2 class="text-center mb-4">
        {{ isEditMode ? "Rechten Bewerken" : "Nieuwe rol toevoegen" }}
      </h2>
    </template>

    <template #content>
      <form @submit.prevent="saveRole">
        <div class="form-group mb-3">
          <label for="name">Naam</label>
          <InputText
            id="name"
            v-model="form.name"
            class="form-control"
            required
          />
        </div>

        <!-- Permissions Checkboxes arranged in two columns -->
        <div class="row justify-content-center">
          <div class="col-md-5" v-for="(label, key) in permissions" :key="key">
            <div class="form-check">
              <Checkbox
                v-model="form.permissions[key]"
                :inputId="key"
                binary
                class="form-check-input"
              />
              <label :for="key" class="form-check-label ms-2">{{
                label
              }}</label>
            </div>
          </div>
        </div>
      </form>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between w-100 mt-2">
        <Button label="Annuleren" class="p-button-secondary" @click="cancel" />
        <Button
          type="submit"
          :label="isEditMode ? 'Bijwerken' : 'Toevoegen'"
          class="p-button-success"
          @click="saveRole"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import axios from "axios";
import Card from "primevue/card";

export default {
  components: {
    InputText,
    Button,
    Checkbox,
    Card,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const form = ref({
      id: null,
      name: "",
      permissions: {},
    });
    const permissions = ref({});
    const isEditMode = ref(false);

    onMounted(() => {
      fetchPermissions();
      if (route.params.id) {
        isEditMode.value = true;
        fetchRole(route.params.id);
      }
    });

    const fetchPermissions = async () => {
      try {
        const response = await axios.get("/beheerapi/roles/permissions");
        permissions.value = response.data;
        // Initialize all permissions as false
        Object.keys(response.data).forEach((key) => {
          form.value.permissions[key] = false;
        });
      } catch (error) {
        console.error("Fout bij het laden van permissies:", error);
      }
    };

    const fetchRole = async (id) => {
      try {
        const response = await axios.get(`/beheerapi/roles/${id}`);
        form.value = response.data;
        form.value.permissions = JSON.parse(response.data.permissions);
      } catch (error) {
        console.error("Fout bij het laden van rolgegevens:", error);
      }
    };

    const saveRole = async () => {
      try {
        if (isEditMode.value) {
          await axios.put(`/beheerapi/roles/${form.value.id}`, form.value);
        } else {
          await axios.post("/beheerapi/roles", form.value);
        }
        router.push("/beheer/roles");
      } catch (error) {
        console.error("Fout bij het opslaan van de rol:", error);
      }
    };

    const cancel = () => {
      router.push("/beheer/roles");
    };

    return {
      form,
      permissions,
      isEditMode,
      saveRole,
      cancel,
    };
  },
};
</script>

<style scoped>
/* No custom styling needed – relying on Bootstrap classes */
</style>
