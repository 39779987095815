<template>
  <div class="container-fluid">
    <div class="d-flex flex-wrap gap-1 mb-1">
      <Button
        class="btn btn-success btn-sm"
        icon="pi pi-globe"
        title="Selectie overzetten naar de kaart"
        @click="navigateTo('/kaart/onderhoud')"
      />
      <Button
        v-if="hasPermission(['admin', 'user', 'export'])"
        class="btn btn-success btn-sm"
        icon="pi pi-file-excel"
        title="Exporteren"
        @click="navigateTo('/onderhoud/export')"
      />
      <Button
        class="btn btn-success btn-sm"
        icon="pi pi-print"
        title="Print maken"
        @click="navigateTo('/onderhoud/print')"
      />
      <Button
        v-if="hasPermission(['super', 'admin', 'update_planning'])"
        class="btn btn-success btn-sm"
        icon="pi pi-calendar"
        title="Planning bijwerken"
        @click="showUpdatePlanning"
      />
      <Button
        v-if="hasPermission(['super', 'admin', 'remind_maintenance'])"
        class="btn btn-success btn-sm"
        icon="pi pi-envelope"
        title="Herinnering onderhoud verzenden"
        @click="remindMaintenance"
      />

      <!-- Status Select -->
      <div
        v-if="
          hasPermission([
            'super',
            'admin',
            'user',
            'maintenance_executed',
            'maintenance_planned',
            'maintenance_postponed',
            'maintenance_cancelled',
            'maintenance_execution_reported',
            'maintenance_vision',
          ])
        "
        class="mb-1"
      >
        <Select
          :options="organisation.maintenance_statuses"
          optionLabel="maintenance_status_nl"
          @change="updateStatus"
          :placeholder="`Status wijzigen (${selectedAmount} geselecteerd)`"
          class="form-select form-select-sm"
        />
      </div>
    </div>

    <!-- Toggle History -->
    <div class="d-flex align-items-center gap-1 mb-1">
      <ToggleSwitch
        inputId="history"
        :model-value="modelValue"
        @update:model-value="historyChange"
        class="form-check-input form-check-sm"
      />
      <label for="history" class="fw-bold mb-0">Geschiedenis</label>
    </div>

    <!-- Reminder Dialog -->
    <Dialog v-model:visible="remind_maintenance" modal class="mw-100">
      <template #header>
        <h6 class="mb-0">Herinnering onderhoud</h6>
      </template>
      <div class="p-1">
        <p class="mb-1">Er wordt een e-mail verstuurd naar:</p>
        <ul class="list-unstyled mb-0">
          <li v-for="email in emails" :key="email" class="small">
            {{ email }}
          </li>
        </ul>
      </div>
      <div class="text-end p-1">
        <Button
          class="btn btn-primary btn-sm"
          label="Herinnering verzenden"
          @click="sendRemindMaintenance"
        />
      </div>
    </Dialog>

    <!-- Reminder Result Dialog -->
    <Dialog v-model:visible="remind_maintenance_result" modal class="mw-100">
      <template #header>
        <h6 class="mb-0">Email Status</h6>
      </template>
      <div class="p-1">
        <h6 class="mb-1">Er zijn succesvol {{ count }} emails verstuurd.</h6>
        <div v-if="emails.length > 0">
          <p class="mb-1">Het is mislukt om een email te sturen naar:</p>
          <ul class="list-unstyled mb-0">
            <li v-for="email in emails" :key="email" class="small">
              {{ email }}
            </li>
          </ul>
        </div>
      </div>
    </Dialog>

    <!-- Planning Update Dialog -->
    <Dialog v-model:visible="showPlanning" modal class="mw-100">
      <template #header>
        <h6 class="mb-0">Bevestig planning bijwerken</h6>
      </template>
      <div class="p-1">
        <ul class="list-unstyled mb-1">
          <li class="small">Uitgevoerd: {{ maintenancePlanning.executed }}</li>
          <li class="small">Uitgesteld: {{ maintenancePlanning.postponed }}</li>
          <li class="small">
            Geannuleerd: {{ maintenancePlanning.cancelled }}
          </li>
        </ul>
      </div>
      <div class="text-end p-1">
        <Button
          class="btn btn-primary btn-sm"
          label="Planning bijwerken"
          @click="updatePlanning"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { inject } from "vue";
import ToggleSwitch from "primevue/toggleswitch";
import axios from "axios";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { ref } from "vue";
import Select from "primevue/select";

export default {
  components: {
    ToggleSwitch,
    Dialog,
    Button,
    Select,
  },
  emits: ["update-table", "update:modelValue"],
  props: {
    selectedAmount: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const remind_maintenance = ref(false);
    const remind_maintenance_result = ref(false);
    const showPlanning = ref(false);
    const emails = ref([]);
    const count = ref(0);
    const maintenancePlanning = ref({});

    const selectAll = () => {
      emit("update-table");
    };

    const navigateTo = (path) => {
      window.open(path, "_blank");
    };

    const clearSelection = () => {
      emit("update-table");
    };

    const changeStatus = (status) => {
      // Placeholder for updating status logic
      emit("update-table");
    };

    const updateTable = () => {
      emit("update-table");
    };

    const historyChange = (value) => {
      emit("update:modelValue", value);
      emit("update-table");
    };

    const checkAuthorization = inject("checkAuthorization");

    const hasPermission = (perms) => {
      return checkAuthorization?.(perms);
    };

    const remindMaintenance = async () => {
      remind_maintenance.value = true;
      count.value = 0;
      emails.value = [];

      const response = await axios.get("/onderhoud/reminder_confirm");
      emails.value = response.data.emails;
    };

    const sendRemindMaintenance = async () => {
      remind_maintenance.value = false;

      const response = await axios.get("/onderhoud/reminder");
      emails.value = response.data.problems;
      count.value = response.data.count;
      remind_maintenance_result.value = true;
    };

    const showUpdatePlanning = async () => {
      showPlanning.value = true;
      maintenancePlanning.value = {};

      const response = await axios.get("/onderhoud/update/planning");
      maintenancePlanning.value = response.data;
    };
    const updatePlanning = async () => {
      await axios.post("/onderhoud/update/planning");
      showPlanning.value = false;
    };

    const updateStatus = async (event) => {
      emit("update-status", event.value.maintenance_status);
    };

    return {
      selectAll,
      clearSelection,
      changeStatus,
      navigateTo,
      hasPermission,
      updateTable,
      historyChange,
      remindMaintenance,
      sendRemindMaintenance,
      updateStatus,
      updatePlanning,
      showUpdatePlanning,
      remind_maintenance,
      emails,
      remind_maintenance_result,
      count,
      showPlanning,
      maintenancePlanning,
    };
  },
};
</script>
  
<style scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Set this to ensure proper alignment */
}

.dialog-content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dialog-content li {
  margin: 5px 0;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.dialog-footer .btn {
  margin: 0 10px; /* Optional: Add space around buttons */
}
</style>
  