<template>
  <Card class="col-md-10 mx-auto">
    <template #title>
      <h2 class="text-center mb-4">
        {{
          isEditMode ? "Instellingen Bewerken" : "Nieuwe Instellingen Toevoegen"
        }}
      </h2>
    </template>

    <template #content>
      <form @submit.prevent="saveSettings">
        <!-- Items Checkboxes arranged in two columns -->
        <div class="row justify-content-center">
          <div class="col-md-5" v-for="item in items" :key="item.id">
            <div class="form-check">
              <Checkbox
                v-model="item.checked"
                :inputId="item.id"
                :binary="true"
                class="form-check-input"
              />
              <label :for="item.id" class="form-check-label ms-2">
                {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </form>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between w-100 mt-2">
        <Button label="Annuleren" class="p-button-secondary" @click="cancel" />
        <Button
          type="submit"
          :label="isEditMode ? 'Bijwerken' : 'Opslaan'"
          class="p-button-success"
          @click="saveSettings"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Card from "primevue/card";
import axios from "axios";

export default {
  components: {
    Button,
    Checkbox,
    Card,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const items = ref([]);
    const isEditMode = ref(false);

    const fetchSettings = async (settingType) => {
      try {
        const response = await axios.get(
          `/beheerapi/settings/types/${settingType}`
        );

        const fetchedData = Array.isArray(response.data)
          ? response.data
          : response.data.items || [];
        if (Array.isArray(fetchedData)) {
          items.value = fetchedData;
        } else {
          console.error("Expected an array but got:", fetchedData);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    watch(
      () => route.params.settingType,
      (newSettingType) => {
        if (newSettingType) {
          isEditMode.value = true;
          fetchSettings(newSettingType);
        }
      },
      { immediate: true }
    );

    const saveSettings = async () => {
      try {
        const payload = items.value.map((item) => ({
          id: item.id,
          checked: item.checked,
        }));
        await axios.put(
          `/beheerapi/settings/types/${route.params.settingType}`,
          payload
        );
        router.push("/beheer");
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    };

    const cancel = () => {
      router.push("/beheer");
    };

    return {
      items,
      isEditMode,
      saveSettings,
      cancel,
    };
  },
};
</script>
