<template>
  <Toast />
  <div class="">
    <h2 class="my-2 d-flex justify-content-center align-items-center">
      <div v-if="previous && previous.id" class="me-2">
        <a
          :href="`/elementen/${previous.id}`"
          :title="`${previous.lsn} - ${
            previous.elementtype?.element_type_nl || ''
          }`"
          class="text-decoration-none"
        >
          <i class="pi pi-chevron-left" style="font-size: 1.5rem"></i>
        </a>
      </div>

        LSN: {{ element.lsn }} -
        {{ element.elementtype?.element_type_nl || "" }}
      
      <div v-if="next && next.id" class="ms-2">
        <a
          :href="`/elementen/${next.id}`"
          :title="`${next.lsn} - ${next.elementtype?.element_type_nl || ''}`"
          class="text-decoration-none"
        >
          <i class="pi pi-chevron-right" style="font-size: 1.5rem"></i>
        </a>
      </div>
    </h2>

    <Tabs value="0">
      <TabList class="tablist">
        <Tab value="0">Algemeen</Tab>
        <Tab value="1" @click="openMap">Kaart</Tab>
        <Tab value="2">Historie</Tab>
        <Tab value="3">Soorten</Tab>
        <Tab value="4">Bedreigingen</Tab>
        <Tab value="5" v-if="hasFormsAccess">Formulieren</Tab>
        <Tab value="6" v-if="hasSubsidyAccess">N.A.W.</Tab>
        <Tab value="7">Mutaties</Tab>
        <Tab value="8" v-if="hasNewUserAccess">Niewe deelnemer</Tab>
      </TabList>
      <TabPanels>
        <div class="row">
          <div class="col-md-6">
            <general-component
              :element="element"
              :element_data="element_data"
              :organisation="organisation"
              :user="user"
            />
          </div>
          <div class="col-md-6">
            <Card class="mb-3">
              <template #content>
                <image-component :element="element" />
              </template>
            </Card>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <TabPanel value="0" as="p" class="m-0">
              <maintenance-component
                :element="element"
                :element_data="element_data"
                :user="user"
                :organisation="organisation"
              />
            </TabPanel>
            <TabPanel value="1" as="p" class="m-0"> </TabPanel>
            <TabPanel value="2" as="p" class="m-0">
              <history-component
                :element="element"
                :element_data="element_data"
                :user="user"
                :organisation="organisation"
              />
            </TabPanel>
            <TabPanel value="3" as="p" class="m-0">
              <species-component
                :element="element"
                :user="user"
                :organisation="organisation"
                :species="species"
              />
            </TabPanel>
            <TabPanel value="4" as="p" class="m-0">
              <threats-component
                :element="element"
                :user="user"
                :organisation="organisation"
                :threats="threats"
              />
            </TabPanel>
            <TabPanel value="5" as="p" class="m-0" v-if="hasFormsAccess">
              <forms-component
                :element="element"
                :organisation="organisation"
                :files="files"
                :filled_forms="filled_forms"
              />
            </TabPanel>
            <TabPanel value="6" as="p" class="m-0" v-if="hasSubsidyAccess">
              <subsidy-component :element="element" />
            </TabPanel>
            <TabPanel value="7" as="p" class="m-0">
              <mutations-component :element="element" />
            </TabPanel>
            <TabPanel value="8" as="p" class="m-0" v-if="hasNewUserAccess">
              <create-component
                :element="element"
                :element_history="element_history"
              />
            </TabPanel>
          </div>

          <div class="col-md-6">
            <overview-map
              :features="features"
              :organisation="organisation"
              :user="user"
            />
          </div>
        </div>
        <TabPanel
          value="0"
          as="p"
          class="m-0"
          v-if="organisation.show_menu_brief"
        >
          <div class="row">
            <div class="col-md-6">
              <files-component
                :element="element"
                :organisation="organisation"
              />
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script>
import { ref, onMounted, computed, provide } from "vue";
import OverviewMap from "./OverviewMap.vue";
import GeneralComponent from "./GeneralComponent.vue";
import ImageComponent from "./ImageComponent.vue";
import SpeciesComponent from "./SpeciesComponent.vue";
import MaintenanceComponent from "./MaintenanceComponent.vue";
import ThreatsComponent from "./ThreatsComponent.vue";
import HistoryComponent from "./HistoryComponent.vue";
import FormsComponent from "./FormsComponent.vue";
import MutationsComponent from "./MutationsComponent.vue";
import SubsidyComponent from "./SubsidyComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import FilesComponent from "./FilesComponent.vue";

import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import Card from "primevue/card";
import Toast from 'primevue/toast';

export default {
  components: {
    GeneralComponent,
    ImageComponent,
    MaintenanceComponent,
    SpeciesComponent,
    ThreatsComponent,
    HistoryComponent,
    FormsComponent,
    MutationsComponent,
    SubsidyComponent,
    CreateComponent,
    OverviewMap,
    FilesComponent,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Card,
    Toast,
  },
  props: {
    element: Object,
    previous: Object,
    next: Object,
    organisation: Object,
    features: Object,
    element_data: Object,
    element_history: Object,
    user: Object,
    species: Object,
    threats: Object,
    files: Object,
    filled_forms: Object,
  },
  setup(props) {
    // Authorization function – executed for every check.
    const checkAuthorization = (perms) => {
      for (const perm of perms) {
        for (const role of props.user.roles) {
          if (role.permissions.includes(perm)) {
            return true;
          }
        }
      }
      return false;
    };

    // Computed property to determine if forms should be displayed.
    const requiredFormsPermissions = [
      "super",
      "upload_form",
      "download_form",
      "fill_form_info",
      "remove_form",
    ];
    const hasFormsAccess = computed(() => {
      const hasForms =
        (props.element.forms && props.element.forms.length) ||
        (props.organisation.forms && props.organisation.forms.length);
      return hasForms && checkAuthorization(requiredFormsPermissions);
    });

    // Computed property for subsidy tab.
    const hasSubsidyAccess = computed(() => {
      return (
        checkAuthorization(["super", "admin", "subsidies"]) &&
        props.element.subsidy
      );
    });

    // Computed property for "Niewe deelnemer" tab.
    const hasNewUserAccess = computed(() => {
      return checkAuthorization(["super", "admin", "add_new_user"]);
    });

    // Provide checkAuthorization to child components
    provide("checkAuthorization", checkAuthorization);

    // Function to open the map in a new window.
    const openMap = () => {
      window.open("/kaart/elementen/" + props.element.id, "_blanc");
    };

    // Handle tab activation based on URL hash.
    const activateTab = (hash) => {
      const tabLink = document.querySelector(`a[href="${hash}"]`);
      if (tabLink) tabLink.click();
    };

    onMounted(() => {
      if (window.location.hash) activateTab(window.location.hash);
    });

    return {
      openMap,
      hasFormsAccess,
      hasSubsidyAccess,
      hasNewUserAccess,
    };
  },
};
</script>

<style scoped>
.tablist >>> .p-tablist-tab-list {
  justify-content: center;
  display: flex;
}

h2 {
  color: #333333;
}
</style>
