/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.VueMultiselect = require('vue-multiselect');
import { createApp } from 'vue';

import '@vuepic/vue-datepicker/dist/main.css';

import store from './store';
window.Vue = require('vue');

import MapComponent from './components/map/MapComponent.vue';
import ShowComponent from './components/element/ShowComponent.vue';
import GeneralComponent from './components/element/GeneralComponent.vue';
import Maintenance from './components/maintenance_table/Maintenance.vue';
import Element from './components/element_table/Element.vue';
import PrimeVue from 'primevue/config';
import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

import 'primeicons/primeicons.css';
import Aura from '@primevue/themes/aura';
import AdminComponent from './components/admin/AdminComponent.vue';
import GeomCru from './components/GeomCru.vue';
import UserOverviewComponent from './components/admin/users/UserOverviewComponent.vue';
import UserComponent from './components/admin/users/UserComponent.vue';
import RolesComponent from './components/admin/roles/RolesComponent.vue';
import RolesOverviewComponent from './components/admin/roles/RolesOverviewComponent.vue';
import DashboardComponent from './components/admin/DashboardComponent.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { Checkbox, ConfirmationService, Ripple, Tab, Tooltip, Tree } from 'primevue';
import MenuOptions from './components/MenuOptions.vue';
import SettingsComponent from './components/admin/SettingsComponent.vue';
import LegendComponent from './components/map/LegendComponent.vue';
import ToolsComponent from './components/map/ToolsComponent.vue';
import ToastService from 'primevue/toastservice';


const listoptions = createApp({
  components: {
    MenuOptions,
  }
});
listoptions.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
listoptions.mount('#listoptions');

const geomcru = createApp({
  components: {
    GeomCru,
  }
});
geomcru.use(store);
geomcru.mount('#geom_cru');

// const formmap = createApp({
//   components: {
//     MapComponent,
// }
// });
// formmap.use(store);
// formmap.mount('#form_map');

const app = createApp({
  components: {
    MapComponent,
    ToolsComponent
  }
});

// Register global components
app.component('Tree', Tree);
app.component('Checkbox', Checkbox);
app.component('Tab', Tab)
app.directive('tooltip', Tooltip);

// Use the store plugin first...
app.use(store);
app.use(ToastService);

// Then use PrimeVue with its configuration
app.use(PrimeVue, {
  ripple: true,
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: 'none'
    }
  }
});

// Finally, mount the app on the element with id "map"
app.mount('#map');


const app3 = createApp({
  components: {
    Maintenance,
  }
});
app3.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app3.use(ConfirmationService)

app3.mount('#maintenance');

const app4 = createApp({
  components: {
    Element,
  }
});

app4.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app4.use(ConfirmationService)

app4.mount('#element');

const app5 = createApp({
  components: {
    ShowComponent,
  }
});
app5.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
app5.use(ConfirmationService)
app5.use(ToastService)
app5.mount('#show')

const routes = [
  { path: '/beheer', component: DashboardComponent },
  { path: '/beheer/users', component: UserOverviewComponent },
  { path: '/beheer/users/new', component: UserComponent },
  { path: '/beheer/users/:id', component: UserComponent },
  { path: '/beheer/roles', component: RolesOverviewComponent },
  { path: '/beheer/roles/new', component: RolesComponent },
  { path: '/beheer/roles/:id', component: RolesComponent },
  { path: '/beheer/settings/:settingType', component: SettingsComponent },
];

const adminRouter = createRouter({
  history: createWebHistory(),
  routes,
});

const admin = createApp({
  components: {
    AdminComponent,
  }
});
admin.directive('ripple', Ripple);
admin.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || 'none',
    }
  }
})
admin.use(adminRouter);
admin.mount('#admin')
