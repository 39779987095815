<template>
  <div v-if="loaded">
    <div class="detail" v-for="feature in features" :key="feature.lsn">
      <!-- Edit page -->
      <div class="edit" v-if="feature.values_.edit">
        <div class="detailform-content">
          <h4>
            Bewerk "{{ feature.get("lsn") }}"
            <a
              class="pt-3 pl-3"
              @click="duplicate(feature)"
              v-if="$store.getters.canCreate"
            >
              <span
                class="glyphicon glyphicon-duplicate"
                aria-hidden="true"
              ></span>
            </a>
            <button
              type="button"
              class="btn btn-default"
              @click="enableEdit(feature)"
            >
              Bewerken
            </button>
          </h4>
          <p>
            Meer informatie:
            <a :href="'/elementen/' + feature.get('id')" target="_blank"
              >Detailpagina</a
            >
          </p>
          <form
            name="opslaan"
            class="iup-form"
            @submit.prevent="processRequest(feature)"
          >
            <fieldset>
              <!-- Locatie -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="location_id">Locatie</label>
                  <Dropdown
                    v-model="feature.values_.location_id"
                    :options="$store.getters.elementsData.locationsArr"
                    optionLabel="location"
                    optionValue="id"
                    placeholder="Select Locatie"
                    required
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Elementtype -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="element_type">Elementtype</label>
                  <Dropdown
                    v-model="feature.values_.element_type_id"
                    :options="$store.getters.elementsData.element_typesArr"
                    optionLabel="element_type_nl"
                    optionValue="id"
                    placeholder="Select Elementtype"
                    required
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Vorm -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="shape_id">Vorm</label>
                  <Dropdown
                    v-model="feature.values_.shape_id"
                    :options="$store.getters.elementsData.shapesArr"
                    optionLabel="shape_nl"
                    optionValue="id"
                    placeholder="Select Vorm"
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Streefbeeld -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="objective_id">Streefbeeld</label>
                  <Dropdown
                    v-model="feature.values_.objective_id"
                    :options="$store.getters.elementsData.objectivesArr"
                    optionLabel="objective_nl"
                    optionValue="id"
                    placeholder="Select Streefbeeld"
                    required
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Diameter -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="diameter_id">Diameter</label>
                  <Dropdown
                    v-model="feature.values_.diameter_id"
                    :options="$store.getters.elementsData"
                    optionLabel="diameter"
                    optionValue="id"
                    placeholder="Select Diameter"
                    required
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Bedekkingsgraad -->
              <div class="row">
                <div class="col-sm-12">
                  <label for="cover">Bedekkingsgraad</label>
                  <Dropdown
                    v-model="feature.values_.cover"
                    :options="coverOptions"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Bedekkingsgraad"
                    class="w-full"
                  />
                </div>
              </div>
              <!-- Aantal -->
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="number"
                    placeholder="Aantal"
                    v-model="feature.values_.number_in_row"
                    name="number_in_row"
                    class="form-control"
                  />
                </div>
              </div>
              <!-- Buttons -->
              <div class="button-row">
                <button type="button" class="btn btn-default" @click="cancel()">
                  Annuleren
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="remove(feature)"
                >
                  Verwijderen
                </button>
                <button type="submit" class="btn btn-success pull-right">
                  Opslaan
                </button>
              </div>
            </fieldset>
          </form>
          <hr />
        </div>
      </div>
      <!-- Detail page -->
      <div class="detail" v-else>
        <h4>
          {{ feature.get("lsn") }} -
          {{
            feature.get("elementtype")
              ? feature.get("elementtype").element_type_nl
              : ""
          }}
          <a
            class="pt-3 pl-3"
            @click="duplicate(feature)"
            v-if="$store.getters.canCreate"
          >
            <span
              class="glyphicon glyphicon-duplicate"
              aria-hidden="true"
            ></span>
          </a>
          <button
            type="button"
            class="btn btn-default"
            v-if="feature.values_.canEdit"
            @click="enableEdit(feature)"
          >
            Bewerken
          </button>
        </h4>
        <p>
          Meer informatie:
          <a :href="'/elementen/' + feature.get('id')" target="_blank"
            >Detailpagina</a
          >
        </p>
        <TabView>
          <TabPanel header="Algemeen">
            <table class="table">
              <tbody>
                <tr>
                  <th>Eigenaar</th>
                  <td>
                    {{
                      feature.get("owners").length
                        ? feature
                            .get("owners")
                            .map((owner) => owner.owner)
                            .toString()
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Locatie</th>
                  <td>
                    {{
                      feature.get("location")
                        ? feature.get("location").location
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Lengte</th>
                  <td>{{ feature.get("length") }}</td>
                </tr>
                <tr>
                  <th>Vorm</th>
                  <td>
                    {{
                      feature.get("shape") ? feature.get("shape").shape_nl : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Opp (m2)</th>
                  <td>{{ feature.get("area") }}</td>
                </tr>
                <tr>
                  <th>Streefbeeld</th>
                  <td>
                    {{
                      feature.get("objective")
                        ? feature.get("objective").objective_nl
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Diameter</th>
                  <td>
                    {{
                      feature.get("diameter")
                        ? feature.get("diameter").diameter
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Aantal</th>
                  <td>{{ feature.get("number_in_row") }}</td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel
            header="Onderhoud"
            v-if="feature.get('maintenance')?.length"
          >
            <template
              v-for="maintenance in feature.get('maintenance')"
              :key="maintenance.id"
            >
              <table class="table">
                <tbody>
                  <tr>
                    <th>Maatregel</th>
                    <td>
                      {{
                        maintenance.maintenancetype
                          ? maintenance.maintenancetype.maintenance_type_nl
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Periode</th>
                    <td>
                      {{
                        new Date(maintenance.period_from).toDateString() +
                        " / " +
                        new Date(maintenance.period_to).toDateString()
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Cyclus</th>
                    <td>
                      {{ maintenance.cycle ? maintenance.cycle.cycle_nl : "" }}
                    </td>
                  </tr>
                  <tr>
                    <th>Fasering</th>
                    <td>
                      {{
                        maintenance.phasing ? maintenance.phasing.phasing : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Uitvoerder</th>
                    <td>{{ maintenance.executed_by_id }}</td>
                  </tr>
                  <tr>
                    <th>Rekenwaarde</th>
                    <td>{{ maintenance.calculation_value }} stuks</td>
                  </tr>
                  <tr>
                    <th>Gereedschap</th>
                    <td>
                      {{
                        maintenance.tool && maintenance.tool.length
                          ? maintenance.tool[0].tool_nl
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Vrijgekomen materiaal</th>
                    <td>
                      {{
                        maintenance.waste && maintenance.waste.length
                          ? maintenance.waste[0].waste_nl
                          : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TabPanel>
        </TabView>
        <hr />
      </div>
    </div>
  </div>
</template>

<style>
div.detail {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10%;
  font-size: 11px;
}
</style>

<script>
import * as moment from "moment";
import axios from "axios";
import GeoJSON from "ol/format/GeoJSON";
import Collection from "ol/Collection";
import Modify from "ol/interaction/Modify";
import { Style, Fill, Stroke, Circle as CircleStyle } from "ol/style.js";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
// PrimeVue components
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Dropdown from "primevue/dropdown";

export default {
  name: "DetailComponent",
  components: {
    TabView,
    TabPanel,
    Dropdown,
  },
  props: {
    user: Object,
    organisation: Object,
    features: Array,
    map: Object,
    infoLayer: Object,
  },
  data() {
    return {
      // Options for Bedekkingsgraad Dropdown
      coverOptions: [
        { value: "0", label: "N.v.t." },
        { value: "10", label: "0 - 10%" },
        { value: "20", label: "10 - 20%" },
        { value: "30", label: "20 - 30%" },
        { value: "40", label: "30 - 40%" },
        { value: "50", label: "40 - 50%" },
        { value: "60", label: "50 - 60%" },
        { value: "70", label: "60 - 70%" },
        { value: "80", label: "70 - 80%" },
        { value: "90", label: "80 - 90%" },
        { value: "99", label: "90 - 100%" },
        { value: "100", label: "100%" },
      ],
      modify: undefined,
      modifyLayer: new Vector({ source: new VectorSource() }),
      loaded: false,
      editFeatures: new Collection(),
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#ffcc33",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    };
  },
  mounted() {
    this.processData();
  },
  unmounted() {
    this.$store.getters.getMap.removeInteraction(this.modify);
    this.$store.getters.getMap.removeLayer(this.modifyLayer);
  },
  methods: {
    processData() {
      this.editFeatures.clear();
      this.features.forEach((f) => {
        f.values_.canEdit = false;
        if (this.organisation.id === 4) {
          for (let owner of f.values_.owners) {
            if (owner.id === this.user.owner_id) {
              f.values_.canEdit = true;
            }
          }
        }
        this.user.roles.forEach((role) => {
          const r = JSON.parse(role.permissions);
          if (
            (r.edit_element_general && this.organisation.id !== 4) ||
            r.admin
          ) {
            f.values_.canEdit = true;
          }
        });
        if (f.values_.canEdit) {
          if (f.values_.begindatum) {
            f.values_.begindatum = moment(
              f.values_.begindatum,
              "YYYY-MM-DD"
            ).toISOString();
          }
          if (f.values_.einddatum) {
            f.values_.einddatum = moment(
              f.values_.einddatum,
              "YYYY-MM-DD"
            ).toISOString();
          }
        }
      });
      this.editFeatureGeometry();
      this.loaded = true;
    },
    remove(feature) {
      if (
        confirm(
          "Weet je zeker dat je het geselecteerde object wilt verwijderen?"
        )
      ) {
        axios({
          method: "delete",
          url: elementDeleteUrl + "/" + feature.get("id"),
        }).then(() => {
          for (let layer of this.$store.getters.getMap.getLayers().array_) {
            if (typeof layer.getSource().clear === "function") {
              layer.getSource().clear();
            } else {
              layer.getSource().refresh();
            }
          }
          this.cancel();
        });
      }
    },
    cancel() {
      this.$store.getters.getMap.removeInteraction(this.modify);
      this.$store.commit("setActiveTab", "Legenda");
    },
    enableEdit(feature) {
      this.modifyLayer.getSource().clear();
      feature.values_.edit = !feature.get("edit");
      this.modifyLayer.getSource().addFeature(feature);
    },
    editFeatureGeometry() {
      this.$store.getters.getMap.addLayer(this.modifyLayer);
      this.modifyLayer.setStyle(this.style);
      if (this.modify) {
        this.$store.getters.getMap.removeInteraction(this.modify);
      }
      this.modify = new Modify({
        source: this.modifyLayer.getSource(),
        style: this.style,
      });
      this.$store.getters.getMap.addInteraction(this.modify);
    },
    processRequest(feature) {
      const element = feature.getProperties();
      if (this.featureType === "elements_nfw_centrale_as") {
        element.organisation_id = 14;
        element.district_id = 25;
        element.created_by = 1;
        delete element.type;
        this.feature.unset("type");
      }
      let editedFeature;
      this.modifyLayer
        .getSource()
        .getFeatures()
        .forEach((f) => {
          if (f.getId() === feature.getId()) {
            editedFeature = f;
          }
        });
      let geom = editedFeature.getGeometry();
      if (geom.getType() === "LineString") {
        let length = Math.round(geom.getLength());
        if (element.length) {
          element.length = length;
        }
      } else if (geom.getType() === "Polygon") {
        let area = Math.round(geom.getArea());
        if (element.area) {
          element.area = area;
        }
      }
      element.geom = new GeoJSON().writeFeatureObject(editedFeature).geometry;
      this.sendRequest(element).then(() => {
        for (let layer of this.$store.getters.getMap.getLayers().array_) {
          if (typeof layer.getSource().clear === "function") {
            layer.getSource().clear();
          } else {
            layer.getSource().refresh();
          }
        }
        this.$store.commit("setActiveTab", "Legenda");
      });
    },
    sendRequest(data) {
      return axios({
        method: "post",
        url: elementsUpdateUrl,
        data,
      });
    },
    duplicate(feature) {
      let newFeature = feature.clone();
      newFeature.getGeometry().translate(1, 0);
      this.$store.commit("setNewFeature", newFeature);
      this.cancel();
      this.$store.commit("setActiveTab", "Element maken");
    },
  },
};
</script>
