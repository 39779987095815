<template>
  <ConfirmDialog />

  <DataTable
    v-if="data"
    :value="data"
    paginator
    sortMode="multiple"
    :lazy="true"
    :rows="pagination.per_page"
    :rowsPerPageOptions="[10, 20, 50, 100]"
    :totalRecords="pagination.total"
    @page="updatePagination"
    @sort="onSort"
    stripedRows
    tableStyle="min-width: 50rem"
    class="table-element"
  >
    <Column
      v-if="hasPermission(['admin', 'user', 'delete_elements'])"
      key="link"
      field="id"
      header="Verwijder"
      :sortable="false"
    >
      <template v-slot:body="slotProps">
        <Button icon="pi pi-trash" @click="confirmDelete(slotProps.data.id)" severity="danger" variant="text" rounded />
      </template>
    </Column>
    <Column key="link" field="id" header="Kaart" :sortable="false">
      <template v-slot:body="slotProps">
        <Button icon="pi pi-map" @click="openMap(slotProps.data.id)" severity="info" variant="text" rounded />
      </template>
    </Column>
    <Column key="lsn" field="lsn" header="LSN" :sortable="true">
      <template #body="slotProps">
        <span v-if="slotProps.data">
          <a :href="'/elementen/' + slotProps.data.id">
            {{ slotProps.data.lsn }}
          </a>
        </span>
        <span v-else>verwijderd</span>
      </template>
    </Column>
    <Column key="notes" field="notes" header="Notities" :sortable="false">
      <template #body="slotProps">
        <Button
          @click="showNotes(slotProps.data)"
          text
          rounded
          severity="secondary"
          :label="
            slotProps.data.notes?.length
              ? slotProps.data.notes.length.toString()
              : ''
          "
          :icon="!slotProps.data.notes?.length ? 'pi pi-plus' : ''"
        />
      </template>
    </Column>

    <Column
      key="element_type"
      field="elementtype.element_type_nl"
      header="Element"
      :sortable="false"
    />

    <!-- If ssbb view -->
    <Column
      v-if="organisation.custom_view === 'sbb'"
      key="subsidy_owner"
      field="subsidy.deeln_naam"
      header="Eigenaar"
      :sortable="false"
    >
      <template #body="slotProps">
        <span v-if="slotProps.data">
          {{
            slotProps.data.owners
              .map((owner, index) => (index ? `, ${owner.owner}` : owner.owner))
              .join("")
          }}
        </span>
        <span v-else>verwijderd</span>
      </template>
    </Column>

    <template v-if="organisation.custom_view !== 'sbb'">
      <Column
        key="subsidy"
        field="subsidy.deeln_naam"
        header="Naam deelnemer"
        :sortable="false"
      />
      <Column
        key="subsidy"
        field="subsidy.pakketnaam"
        header="Beheerpakket Naam"
        :sortable="false"
      />
      <Column
        key="subsidy"
        field="subsidy.pakketgroep"
        header="Beheerpakket Groep"
        :sortable="false"
      />
    </template>

    <Column
      key="location"
      field="location.location"
      header="Locatie"
      :sortable="true"
    />

    <template v-if="organisation.custom_view === 'sbb'">
      <Column
        key="objective"
        field="objective.objective_nl"
        header="Streefbeeld"
        :sortable="false"
      />
      <Column
        key="diameter"
        field="diameter.diameter"
        header="Diameter"
        :sortable="false"
      />
      <Column key="length" field="length" header="Lengte" :sortable="false" />
      <Column key="area" field="area" header="Oppervlakte" :sortable="true" />
      <Column key="count" field="count" header="Stuks" :sortable="false" />
    </template>
  </DataTable>
  <Dialog
    v-model:visible="noteModal"
    :draggable="false"
    modal
    header="Notities"
    :style="{ width: '35vw', height: '50rem' }"
  >
    <NotesContent
      v-if="selectedElement"
      :notes="selectedElement.notes"
      :elementId="selectedElement.id"
      permissionForAdd="add_element_notes"
      @note-added="noteAdded"
      @note-updated="noteUpdated"
      @note-deleted="noteDeleted"
    />
  </Dialog>
</template>

<script>
import { ref, inject } from "vue";
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

import NotesContent from "../NotesContent.vue";

export default {
  name: "General",
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Dialog,
    Button,
    NotesContent,
    ConfirmDialog
  },
  props: {
    element: Object,
    element_data: Object,
    organisation: Object,
    user: Object,
    data: Array,
    pagination: Object,
  },
  setup() {
    const confirm = useConfirm();
    return { confirm };
  },
  data() {
    return {
      editing: false,
      suggestions: {},
      fields: [
        { cover: "N.v.t.", value: null },
        { cover: "0-10%", value: 0 },
        { cover: "10-20%", value: 10 },
        { cover: "20-30%", value: 20 },
        { cover: "30-40%", value: 30 },
        { cover: "40-50%", value: 40 },
        { cover: "50-60%", value: 50 },
        { cover: "60-70%", value: 60 },
        { cover: "70-80%", value: 70 },
        { cover: "80-90%", value: 80 },
        { cover: "90-100%", value: 90 },
        { cover: "100%", value: 100 },
      ],
      noteModal: false,
      inputNoteText: "",
      editingNoteId: null,
      editingNoteText: "",
      sort: null,
      selectedElement: null,
    };
  },
  methods: {
    updatePagination(event) {
      this.$emit("pagination-changed", {
        page: event.page + 1,
        perPage: event.rows,
        sort: this.sort,
      });
    },
    showNotes(element) {
      this.selectedElement = element;
      this.noteModal = true;
    },
    async noteAdded(newNote) {
      if (this.selectedElement) {
        this.selectedElement.notes.unshift(newNote);
      }
    },
    noteUpdated(updatedNote) {
      if (this.selectedElement) {
        const index = this.selectedElement.notes.findIndex(
          (n) => n.id === updatedNote.id
        );
        if (index !== -1) {
          this.selectedElement.notes.splice(index, 1, updatedNote);
        }
      }
    },
    noteDeleted(deletedNote) {
      if (this.selectedElement) {
        const index = this.selectedElement.notes.findIndex(
          (n) => n.id === deletedNote.id
        );
        if (index !== -1) {
          this.selectedElement.notes.splice(index, 1);
        }
      }
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("en-US", options)
        .replace(",", "");
    },
    hasPermission(perms) {
      const checkAuthorization = inject("checkAuthorization");
      return checkAuthorization?.(perms) || false;
    },
    search(event, fieldName) {
      const query = event.query.toLowerCase();
      const keysToCheck = [
        "owner",
        "location",
        "element_type_nl",
        "district",
        "shape_nl",
        "objective_nl",
        "diameter",
        "project",
      ];
      const filtered = Object.values(this.element_data[fieldName]).filter(
        (item) => {
          if (typeof item === "string") {
            return item.toLowerCase().includes(query);
          } else if (typeof item === "object" && item !== null) {
            return keysToCheck.some(
              (key) => key in item && item[key].toLowerCase().includes(query)
            );
          }
          return false;
        }
      );
      this.suggestions[fieldName] = filtered;
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    async saveChanges() {
      this.toggleEdit();
      const body = {
        id: this.element.id,
        district_id: this.element.district?.id || null,
        location_id: this.element.location?.id,
        owners: this.element.owners,
        element_type_id: this.element.elementtype.id,
        shape_id: this.element.shape?.id || null,
        objective_id: this.element.objective?.id || null,
        length: this.element.length || null,
        area: this.element.area || null,
        diameter_id: this.element.diameter?.id || null,
        cover: this.element.cover || null,
        number_in_row: this.element.number_in_row || null,
      };
      try {
        const response = await axios.post("/elementen/store", body);
        console.log(response);
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    },
    confirmDelete(id) {
      console.log('test')
      this.confirm.require({
        message: "Weet u zeker dat u dit element wilt verwijderen?",
        header: "Bevestig Verwijdering",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Verwijderen",
        rejectLabel: "Annuleren",
        accept: async () => {
          try {
            const response = await axios.delete("/elementen/" + id);
            this.$emit("pagination-changed", {});
            console.log(response);
          } catch (error) {
            console.error("Error deleting element:", error);
          }
        },
      });
    },
    onSort(event) {
      this.sort = event.multiSortMeta;
      this.$emit("pagination-changed", { sort: this.sort });
    },
    openMap(id) {
      window.open(`/kaart/elementen/${id}`, '_blank');
    },
  },
};
</script>

<style scoped>
.click-open-notes {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #bbb;
}
</style>
