<template>
  <div class="container">
    <h5>Optieslijst beschrijving</h5>

    <table class="table table-hover">
      <thead>
        <tr>
          <th>Lijst</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(list, key) in lists" :key="key">
          <td>
            <button
              class="btn btn-default btn-sm"
              @click="openEditDialog(key, list)"
              title="Bewerken"
            >
              <span class="pi pi-pencil"></span>
            </button>
          </td>
          <td>{{ key }}</td>
        </tr>
      </tbody>
    </table>

    <!-- PrimeVue Dialog -->
    <Dialog 
      v-model:visible="showDialog" 
      :header="dialogTitle" 
    >
      <div class="form-templates">
        <div class="listoption-template">
          <div v-for="object in availableObjects" :key="object.id">
            <Checkbox 
              v-model="object.selected"
              binary
            />
            <label>{{ object[currentList.name] }}</label>
          </div>
        </div>
      </div>

      <template #footer>
        <Button 
          label="Opslaan" 
          class="p-button-primary" 
          @click="submitForm" 
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import axios from "axios";

export default {
  name: 'ListOptions',
  components: { 
    Dialog,
    Button,
    Checkbox 
  },

  data() {
    return {
      availableObjects: [],
      currentList: null,
      dialogTitle: '',
      showDialog: false,
    };
  },

  props: {
    lists: Object,
    organisation: Object
  },

  methods: {
    async openEditDialog(type, list) {
      this.currentList = list;
      this.currentList.type = type;
      this.dialogTitle = `Opties bewerken: ${type}`;

      // Fetch beschikbare objecten voor de lijst
      console.log(type, list)
      const response = await axios.get(`/instellingen/menuopties/single?model=${list.model}&type=${type}&name=${list.name}`)
      console.log(response)
      this.availableObjects = response.data;

      this.showDialog = true;
    },

    submitForm() {
      const payload = {
        model: this.currentList.model,
        type: this.currentList.type,
        name: this.currentList.name,
        list: this.availableObjects,
      };

      // Verstuur de payload naar de server
      axios.post('/instellingen/listoptions/edit', payload)
        .then(() => {
          this.showDialog = false;
          // this.$toast.add({ severity: 'success', summary: 'Succes', detail: 'Opties opgeslagen', life: 3000 });
        })
        .catch(() => {
          // this.$toast.add({ severity: 'error', summary: 'Fout', detail: 'Opslaan mislukt', life: 3000 });
        });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}
</style>
