<template>
  <Card class="my-2">
    <template #title>
      <h4>Mutaties</h4>
    </template>
    <template #content>
      <ul class="list-group list-group-flush">
        <li
          v-for="(mutation, index) in element.mutations"
          :key="'mutation-' + index"
          class="list-group-item"
        >
          <!-- Header row with date and user info -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <span class="badge bg-secondary">
              {{ new Date(mutation.created_at).toLocaleDateString() }}
            </span>
            <span class="text-body-secondary">
              <template v-if="mutation.user">
                {{ mutation.user.first_name }} {{ mutation.user.last_name }}
              </template>
              <template v-else> Verwijderde gebruiker </template>
            </span>
          </div>

          <!-- Mutation message -->
          <p>
            <template v-if="mutation.action === 'updated'">
              <template v-if="mutation.attribute === 'status'">
                <strong>Status</strong> van
                <strong>{{ mutation.old_value }}</strong> naar
                <strong>{{ mutation.new_value }}</strong> gewijzigd.
              </template>
              <template v-else-if="mutation.attribute === 'measure'">
                <strong>Maatregel</strong> van
                <strong>{{ mutation.old_value }}</strong> naar
                <strong>{{ mutation.new_value }}</strong> gewijzigd.
              </template>
              <template v-else-if="mutation.attribute === 'lsn'">
                <strong>LSN</strong> van
                <strong>{{ mutation.old_value }}</strong> naar
                <strong>{{ mutation.new_value }}</strong> gewijzigd.
              </template>
              <template v-else>
                Bewerkt:
                <template
                  v-if="mutation.relation_model === 'MaintenanceStatus'"
                >
                  onderhoudstatus <strong>{{ mutation.attribute }}</strong> van
                  <strong>{{
                    mutation.old_relation.maintenance_status_nl
                  }}</strong>
                  naar
                  <strong>{{
                    mutation.new_relation.maintenance_status_nl
                  }}</strong
                  >.
                </template>
                <template v-else-if="mutation.relation_model === 'Tool'">
                  gereedschap <strong>{{ mutation.attribute }}</strong> van
                  <strong>{{ mutation.old_relation.tool_nl }}</strong> naar
                  <strong>{{ mutation.new_relation.tool_nl }}</strong
                  >.
                </template>
                <template v-else-if="mutation.relation_model === 'Waste'">
                  vrijgekomen materiaal
                  <strong>{{ mutation.attribute }}</strong> van
                  <strong>{{ mutation.old_relation.waste_nl }}</strong> naar
                  <strong>{{ mutation.new_relation.waste_nl }}</strong
                  >.
                </template>
                <template v-else-if="mutation.relation_model === 'User'">
                  eigenaar <strong>{{ mutation.attribute }}</strong> van
                  <strong>{{ mutation.old_relation.username }}</strong> naar
                  <strong>{{ mutation.new_relation.username }}</strong
                  >.
                </template>
              </template>
            </template>

            <template v-else-if="mutation.action === 'created'">
              Heeft
              <template v-if="mutation.relation_model === 'Flora'">
                flora <strong>{{ mutation.new_relation.flora_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Fauna'">
                fauna <strong>{{ mutation.new_relation.fauna_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Threat'">
                bedreiging
                <strong>{{ mutation.new_relation.threat_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Disease'">
                ziekte <strong>{{ mutation.new_relation.disease_nl }}</strong>
              </template>
              <template v-else-if="mutation.model === 'Maintenance'">
                maatregel <strong>{{ mutation.new_value }}</strong>
              </template>
              <template
                v-else-if="
                  mutation.model === 'Element' &&
                  !mutation.new_value &&
                  !mutation.old_value
                "
              >
                het element
              </template>
              <template v-else>
                <strong
                  >{{ mutation.attribute }} {{ mutation.old_value }}</strong
                >
              </template>
              toegevoegd.
            </template>

            <template v-else-if="mutation.action === 'deleted'">
              Heeft
              <template v-if="mutation.relation_model === 'Flora'">
                flora <strong>{{ mutation.old_relation.flora_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Fauna'">
                fauna <strong>{{ mutation.old_relation.fauna_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Threat'">
                bedreiging
                <strong>{{ mutation.old_relation.threat_nl }}</strong>
              </template>
              <template v-else-if="mutation.relation_model === 'Disease'">
                ziekte <strong>{{ mutation.old_relation.disease_nl }}</strong>
              </template>
              <template v-else-if="mutation.model === 'Maintenance'">
                maatregel <strong>{{ mutation.new_value }}</strong>
              </template>
              <template
                v-else-if="
                  mutation.model === 'Element' &&
                  !mutation.new_value &&
                  !mutation.old_value
                "
              >
                het element
              </template>
              <template v-else>
                <strong
                  >{{ mutation.attribute }} {{ mutation.old_value }}</strong
                >
              </template>
              verwijderd.
            </template>
          </p>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script>
import { Card } from "primevue";
import { ref } from "vue";

export default {
  name: "Mutations",
  props: {
    element: Object,
  },
  components: {
    Card,
  },
  setup(props) {
    const lastDate = ref(null);
    console.log("mutations", props.element.mutations);

    // const filteredMutations = computed(() =>
    //   props.element.mutations.filter(
    //     (mutation) => mutation.relation_model !== "ShapeImportMatch"
    //   )
    // );

    const isNewDate = (date) => {
      const formattedDate = new Date(date).toLocaleDateString();
      if (formattedDate !== lastDate.value) {
        lastDate.value = formattedDate;
        return true;
      }
      return false;
    };

    return {
      isNewDate,
    };
  },
};
</script>

<style scoped>
.date {
  font-weight: bold;
  margin-top: 10px;
}

.history {
  max-height: 400px;
  overflow-y: auto;
}
</style>
