<template>
  <div class="container-fluid">
    <div class="row g-3 p-2">
      <!-- Left Menu: MaintenanceRefinements -->
      <div class="col-12 col-md-3 col-lg-2">
        <Card class="shadow-sm">
          <template #content>
            <MaintenanceRefinements
              :filters="filters"
              @filter-change="handleFilterChange"
            />
          </template>
        </Card>
      </div>

      <!-- Right Main Content -->
      <div class="col-12 col-md-9 col-lg-10">
        <Card class="shadow-sm mb-3">
          <template #content>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <MaintenanceButtons
                  :organisation="organisation"
                  @update-table="updateSearch"
                  @update-status="updateStatus"
                  v-model:history="history"
                  :selected-amount="selectedAmount"
                  @update:model-value="(value) => (history = value)"
                />
              </div>
              <div class="d-flex align-items-center">
                <InputGroup>
                  <InputText
                    v-model="search"
                    placeholder="Zoeken op LSN"
                    @input="updateSearch"
                    class="p-inputtext-lg"
                  />
                  <InputGroupAddon>
                    <strong>{{ pagination.total }}</strong
                    >&nbsp;resultaten
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </template>
        </Card>

        <!-- Table Card -->
        <Card class="shadow-sm">
          <template #content>
            <MaintenanceTable
              ref="maintenanceTable"
              :data="data"
              :pagination="pagination"
              :organisation="organisation"
              @rows-changed="selectedRowsChange"
              @pagination-changed="handlePaginationChange"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>

  <!-- Feature Message Dialog -->
  <Dialog
    v-model:visible="showFeatureMessage"
    :draggable="false"
    modal
    header="Welkom bij de nieuwe update van BOOM!"
    :style="{ width: '35vw', height: '50rem' }"
  >
    <div class="p-3">
      <p>
        We zijn verheugd om onze nieuwste website-update met jullie te delen!
        Deze update markeert een belangrijke stap in het verbeteren van jouw
        ervaring op onze website. Dit is wat we tot nu toe hebben verbeterd:
      </p>
      <ul>
        <li>
          <strong>Moderne functionaliteiten:</strong> We hebben de
          functionaliteiten van de website aangepast aan de nieuwste
          technologieën, zodat je kunt genieten van een moderne,
          gebruiksvriendelijke ervaring.
        </li>
        <li>
          <strong>Verhoogde beveiliging:</strong> Jouw veiligheid staat bij ons
          voorop. We hebben de beveiliging van onze website verder versterkt om
          jouw gegevens beter te beschermen.
        </li>
        <li>
          <strong>Snelle laadtijden:</strong> In het veld wachten tot de
          elementen zijn ingeladen is natuurlijk niet gewenst, nu worden ze
          weergegeven in een mum van tijd.
        </li>
      </ul>
      <h4 class="p-dialog-title">We blijven doorontwikkelen</h4>
      <p>
        Deze update is nog maar het begin. We werken voortdurend aan het
        verbeteren van onze functionaliteiten, en de komende tijd kun je nog
        meer verbeteringen verwachten. Vooral binnen onze applicatie
        <strong>BOOM</strong> komen er binnenkort nieuwe updates om jouw
        ervaring nóg beter en efficiënter te maken.
      </p>
      <p>
        Heb je vragen of ervaar je problemen? Laat het ons weten via onze
        <a href="/contact">contactpagina</a>. We staan klaar om je te helpen!
      </p>
      <p>
        Bedankt voor je vertrouwen en we hopen dat je geniet van de vernieuwde
        website én van de aankomende verbeteringen.
      </p>
    </div>
  </Dialog>
</template>



<!-- <template>
  <div class="row g-3">
    <div class="col-12 col-md-3 col-lg-2">
      <MaintenanceRefinements
        :filters="filters"
        @filter-change="handleFilterChange"
      />
    </div>

    <div class="col-12 col-md-9 col-lg-10">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row align-items-center gy-3">
            <div class="col-12 col-md-7">
              <MaintenanceButtons
                :organisation="organisation"
                @update-table="updateSearch"
                @update-status="updateStatus"
                v-model:history="history"
                :selected-amount="selectedAmount"
                @update:model-value="(value) => (history = value)"
              />
            </div>

            <div class="col-12 col-md-5">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  v-model="search"
                  placeholder="Zoeken op LSN"
                  @input="updateSearch"
                />
              </div>
              <p class="mt-2 text-body-secondary">
                <b>{{ pagination.total }}</b> resultaten
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-9 col-lg-10">
      <div class="card shadow-sm">
        <div class="card-body">
          <MaintenanceTable
            ref="maintenanceTable"
            :data="data"
            :pagination="pagination"
            :organisation="organisation"
            @rows-changed="selectedRowsChange"
            @pagination-changed="handlePaginationChange"
          />
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="showFeatureMessage"
    :draggable="false"
    modal
    header="Welkom bij de nieuwe update van BOOM!"
    :style="{ width: '35vw', height: '50rem' }"
  >
    <div class="p-3">
      <p>
        We zijn verheugd om onze nieuwste website-update met jullie te delen!
        Deze update markeert een belangrijke stap in het verbeteren van jouw
        ervaring op onze website. Dit is wat we tot nu toe hebben verbeterd:
      </p>
      <ul>
        <li>
          <strong>Moderne functionaliteiten:</strong> We hebben de
          functionaliteiten van de website aangepast aan de nieuwste
          technologieën, zodat je kunt genieten van een moderne,
          gebruiksvriendelijke ervaring.
        </li>
        <li>
          <strong>Verhoogde beveiliging:</strong> Jouw veiligheid staat bij ons
          voorop. We hebben de beveiliging van onze website verder versterkt om
          jouw gegevens beter te beschermen.
        </li>
        <li>
          <strong>Snelle laadtijden:</strong> In het veld wachten tot de
          elementen zijn ingeladen is natuurlijk niet gewenst, nu worden ze
          weergegeven in een mum van tijd.
        </li>
      </ul>
      <h4 class="p-dialog-title">We blijven doorontwikkelen</h4>
      <p>
        Deze update is nog maar het begin. We werken voortdurend aan het
        verbeteren van onze functionaliteiten, en de komende tijd kun je nog
        meer verbeteringen verwachten. Vooral binnen onze applicatie
        <strong>BOOM</strong> komen er binnenkort nieuwe updates om jouw
        ervaring nóg beter en efficiënter te maken.
      </p>
      <p>
        Heb je vragen of ervaar je problemen? Laat het ons weten via onze
        <a href="/contact">contactpagina</a>. We staan klaar om je te helpen!
      </p>
      <p>
        Bedankt voor je vertrouwen en we hopen dat je geniet van de vernieuwde
        website én van de aankomende verbeteringen.
      </p>
    </div>
  </Dialog>
</template> -->

  
<script>
import { ref, reactive, onMounted, provide, defineProps, toRaw } from "vue";
import axios from "axios";
import _ from "lodash";
import MaintenanceTable from "./MaintenanceTable.vue";
import MaintenanceButtons from "./MaintenanceButtons.vue";
import MaintenanceRefinements from "./MaintenanceRefinements.vue";
import Dialog from "primevue/dialog";
import { Card, InputGroup, InputGroupAddon, InputText } from "primevue";

export default {
  components: {
    MaintenanceRefinements,
    MaintenanceButtons,
    MaintenanceTable,
    Dialog,
    InputText,
    Card,
    InputGroup,
    InputGroupAddon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      filters: [],
      showArchived: false,
      maintenance: [],
      data: [],
      history: false,
      pagination: {
        current_page: 0,
        last_page: 1,
        per_page: 10,
        total: 0,
        sort: undefined,
      },
      selectedRows: [],
      showFeatureMessage: false,
    };
  },
  mounted() {
    this.getFilters();
    this.getData();

    const lastSeen = localStorage.getItem("featureSeenAt");
    const currentDate = new Date();
    const weeksSinceLastSeen = lastSeen
      ? (currentDate - new Date(lastSeen)) / (1000 * 60 * 60 * 24 * 7)
      : null;

    if (!lastSeen || weeksSinceLastSeen > 3) {
      this.showFeatureMessage = true;
    }
    localStorage.setItem("featureSeenAt", new Date().toISOString());
  },
  computed: {
    // Computed property to get the count of selected rows
    selectedAmount() {
      return this.selectedRows.length;
    },
  },
  methods: {
    async updateStatus(status) {
      if (this.selectedRows.length < 1) {
        console.error("No items selected");
        return;
      }

      const ids = this.selectedRows.map((item) => item.id);

      // Finish this tomorrow
      const response = await axios.post("/onderhoud/update/status", {
        items: ids,
        status: status,
      });

      this.$refs.maintenanceTable.clearSelection();

      this.getData();
    },
    async getData() {
      const response = await axios.get(maintenanceTableUrl, {
        params: {
          page: this.pagination.current_page,
          per_page: this.pagination.per_page,
          search: this.search,
          history: this.history,
          sort: JSON.stringify(this.pagination.sort),
        },
      });

      this.data = response.data.data;
      this.pagination = {
        current_page: response.data.current_page,
        last_page: response.data.last_page,
        per_page: +response.data.per_page,
        total: response.data.total,
      };
    },
    getFilters() {
      try {
        axios.get(maintenanceGetFiltersUrl).then((response) => {
          console.log(response);
          this.filters = response.data.filters;
          this.showArchived = response.data.show_archived;
        });
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    },
    async postFilters() {
      const response = await axios.post(maintenancePostFiltersUrl, {
        filters: this.filters,
      });

      this.getData();
      // Get new filters to update the count values,
      this.getFilters();

      console.log(response);
    },
    updateSearch() {
      this.getData();
    },
    handleFilterChange() {
      console.log("Nieuwe filters!", this.filters);
      this.postFilters();
    },
    selectedRowsChange(event) {
      this.selectedRows = event.rows;
      // console.log(this.selectedRows.values());
    },
    handlePaginationChange({ page, perPage, sort }) {
      // Werk paginering bij met nieuwe waarden
      if (page) this.pagination.current_page = page;
      if (perPage) this.pagination.per_page = perPage;
      if (sort) this.pagination.sort = sort;
      this.getData();
    },
  },
  setup(props) {
    // Functie om permissies te controleren
    function checkAuthorization(perms) {
      for (const perm of perms) {
        for (const role of props.user.roles) {
          if (role.permissions.includes(perm)) {
            return true;
          }
        }
      }
      return false;
    }

    // Maak de checkAuthorization-functie beschikbaar via provide
    provide("checkAuthorization", checkAuthorization);

    return {
      user: props.user, // Maak user beschikbaar in de template
    };
  },
};
</script>

<style scoped>
/* Component-specific styles */
</style>
  